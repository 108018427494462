/* eslint-disable array-callback-return */
/* eslint-disable no-plusplus */
/* eslint-disable no-bitwise */
import * as Yup from 'yup';
import React, {useEffect, useState} from 'react';
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {useSnackbar} from "notistack";
import { useNavigate, useParams } from 'react-router-dom';
import * as PropTypes from "prop-types";
// @mui
import { useTheme, styled } from '@mui/material/styles';
import {
  Card,
  Button,
  Container,
  Typography,
  DialogContentText,
  Grid,
  Stack,
  Autocomplete,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  Table,
  TableHead,
  Paper,
  Tooltip,
  IconButton,
  Dialog, DialogTitle, DialogActions, DialogContent, TextField,
} from '@mui/material';
import {DateTimePicker, LoadingButton} from '@mui/lab';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../../components/Loading';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import Page from '../../../components/Page';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import {
  createEntitlements, getEntitlementsDetail,
  getEntitlementTypeDetail, getEntitlementTypeDetailSuccess, getEvents, updateEntitlementDetail, updateEntitlements,
} from "../../../redux/slices/entitlement";
import {ERROR_MESSAGE} from "../../../config";


// ----------------------------------------------------------------------

const DialogContentTextStyle = styled(DialogContentText)(({ theme }) => ({
  fontWeight: 'bold',
}));

function MuiPickersUtilsProvider(props) {
  return null;
}

MuiPickersUtilsProvider.propTypes = {
  utils: PropTypes.any,
  children: PropTypes.func
};
export default function TypeDetailEntitlement() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { entitlementTypeDetail, events, isLoading } = useSelector((state) => state.entitlement);
  const { enqueueSnackbar } = useSnackbar();

  const KeySettingSchema = Yup.object().shape({
    price: Yup.number().required('price is required'),
    currency: Yup.string().required('currency is required'),
    limit: Yup.number().required('limit is required'),
    currency_decimal: Yup.number().required('currency_decimal is required'),
  });

  useEffect(() => {
    if (id) {
      dispatch(getEntitlementTypeDetail(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(getEntitlementTypeDetailSuccess(null));
    if (id) {
      dispatch(getEntitlementTypeDetail(id));
    }
  }, []);

  useEffect(() => {
    reset(defaultValues);
  }, [entitlementTypeDetail]);


  const defaultValues = {
    price: entitlementTypeDetail?.price || 0,
    currency: entitlementTypeDetail?.currency || '',
    event: entitlementTypeDetail?.referral_event?.name || '',
    limit : entitlementTypeDetail?.limit || 0,
    currency_decimal : entitlementTypeDetail?.currency_decimal || 0,
  };

  const methods = useForm({
    resolver: yupResolver(KeySettingSchema),
    defaultValues,
  });

  const {
    reset,
    getValues,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;


  const onSubmit = async (data) => {
    const newData = {
        price: +data.price,
        currency: data.currency,
        limit: +data.limit,
        currency_decimal: +data.currency_decimal,
    };
    const response =  await updateEntitlementDetail(newData, id)
    if (response?.status === 200 ) {
      dispatch(getEntitlementTypeDetail(id));
      enqueueSnackbar('Update entitlement detail success' );
      navigate(-1);
      reset();
    }
    else {
      enqueueSnackbar(response?.error?.detail ||  'Internal server error', { variant: 'error' });
    }
  };

  return (
      <Page title="Entitlement Type Detail">
        {isLoading && <Loading />}
        <Container>
          <HeaderBreadcrumbs
              heading={entitlementTypeDetail?.name || ''}
              links={[
                { name: 'Affiliate Types', href: PATH_DASHBOARD.referral.entitlements },
                { name:  entitlementTypeDetail?.name || ''},
              ]}
              action={
                <Stack spacing={2} mt={4} width="100%" direction="row" justifyContent="flex-end">
                  <Button color="inherit" variant="outlined" size="large" onClick={ id ? () => navigate(-1) : () => navigate(-2) }>
                    Back
                  </Button>
                  <LoadingButton variant="contained" loading={isSubmitting} onClick={() => onSubmit(getValues())}>
                    {id ? 'Update' : 'Create'}
                  </LoadingButton>
                </Stack>
              }
          />
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <Card mt={2} sx={{ p: 2, mb: 2  }}>
                <Stack spacing={1}>
                  <Typography variant="h3">Information</Typography>
                  <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2} sx={{ marginTop: 0 }}>
                      <Grid item xs={4}>
                        <DialogContentTextStyle>Event: </DialogContentTextStyle>
                      </Grid>
                      <Grid item xs={6}>
                        <RHFTextField fullWidth disabled name="event" label="event" />
                      </Grid>
                      <Grid item xs={4}>
                        <DialogContentTextStyle>Price: </DialogContentTextStyle>
                      </Grid>
                      <Grid item xs={6}>
                        <RHFTextField fullWidth name="price" label="price" />
                      </Grid>
                      <Grid item xs={4}>
                        <DialogContentTextStyle>Limit: </DialogContentTextStyle>
                      </Grid>
                      <Grid item xs={6}>
                        <RHFTextField fullWidth name="limit" label="limit" />
                      </Grid>
                      <Grid item xs={4}>
                        <DialogContentTextStyle>Currency: </DialogContentTextStyle>
                      </Grid>
                      <Grid item xs={6}>
                        <RHFTextField fullWidth name="currency" label="currency" />
                      </Grid>
                      <Grid item xs={4}>
                        <DialogContentTextStyle>Currency Decimal: </DialogContentTextStyle>
                      </Grid>
                      <Grid item xs={6}>
                        <RHFTextField fullWidth name="currency_decimal" label="currency_decimal" />
                      </Grid>
                    </Grid>
                  </FormProvider>
                </Stack>
              </Card>
            </Grid>
            </Grid>
        </Container>
      </Page>
  );
}
