import { capitalCase } from 'change-case';
import React, { useState } from 'react';
import {useSnackbar} from "notistack";

import {
  Container,
  Box,
  Tabs,
  Tab,
  Button,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  TextField
} from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
import {createLink} from '../../../redux/slices/link';
// components
import Page from '../../../components/Page';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
// sections
import {Revenues} from '../../../sections/@dashboard/link/management';

// ----------------------------------------------------------------------
const TABLE_HEAD = [
  { id: 'referral_url', label: 'Referral Link', alignRight: false },
  { id: 'referral_event', label: 'Referral Event', alignRight: false },
  { id: 'user', label: 'User' },
  { id: 'created_at', label: 'Created at', alignRight: false },
  { id: 'referral_contract', label: 'Affiliates', alignRight: false },
  { id: 'total_revenue', label: 'Revenue', alignRight: false },
  { id: 'is_paid', label: 'Is Paid', alignRight: false },
];

export default function LinkRevenue() {
  const [dataChanged, setDataChanged] = useState(false);
  const [currentTab, setCurrentTab] = useState('revenues');


  const handleChangeTab = (e, value) => {
    setCurrentTab(value);
  };

  const PROFILE_TABS = [
    {
      value: 'revenues',
      component: <Revenues tableHead={TABLE_HEAD} dataChanged={dataChanged}  setDataChanged={setDataChanged} defaultContract={""}/>,
    },
  ];

  return (
      <Page title="Link: Tracking">
        <Container>
          <HeaderBreadcrumbs
              heading="Tracking"
              links={[{ name: 'Tracking', href: PATH_DASHBOARD.referral.revenue }, { name: 'List' }]}
          />
          <Tabs
              value={currentTab}
              scrollButtons="auto"
              variant="scrollable"
              allowScrollButtonsMobile
              sx={{ marginLeft: '24px' }}
              onChange={handleChangeTab}
          >
            {PROFILE_TABS.map((tab) => (
                <Tab disableRipple key={tab.value} value={tab.value} icon={tab.icon} label={capitalCase(tab.value)} />
            ))}
          </Tabs>
          {PROFILE_TABS.map((tab) => {
            const isMatched = tab.value === currentTab;
            return isMatched && <Box key={tab.value}>{tab.component}</Box>;
          })}
        </Container>
      </Page>
  );
}
