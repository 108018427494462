import merge from 'lodash/merge';
import ReactApexChart from 'react-apexcharts';
import {useEffect, useState, useRef} from 'react';
// @mui
import { Card, CardHeader, Box } from '@mui/material';

import { BaseOptionChart } from '../../../../components/chart';

// ----------------------------------------------------------------------


export default function AnalyticsEvent({events,dates,setDate,setCollapsedSeries}) {
  const [chartData, setChartData] = useState([]); // [events, dates, isLoading

  useEffect(() => {
    const data = []
    if (events) {
      events.forEach((event, index) => {
        data.push({
          name: event.name,
          data: event.data,
          type: 'area',
        });
      })
      setChartData(data)
    }
  }, [events]);


  const chartOptions = merge(BaseOptionChart(), {
    labels: dates,
    xaxis: { type: 'datetime' },
    fill: { type: 'gradient' },
    stroke: {
      width: 2,
      curve: 'smooth',
      lineCap: 'round',
    },
    chart: {
      events: {
          updated(chartContext, config) {
            setTimeout(() => {
              const collapsedSeriesIndex = chartContext.w.globals.collapsedSeries.map(series => series.index);
              const collapsedSeries = collapsedSeriesIndex.map(index => events[index].name);
              console.log('collapsedSeries', collapsedSeries);
              setCollapsedSeries(collapsedSeries);
            }, 0);
          },
          click(event, chartContext, config) {
            if (config.dataPointIndex !== -1) {
              const clickedDate = dates[config.dataPointIndex];
              setDate(clickedDate);
            }
          },
        },
      },
    });

  return (
    <Card>
      <CardHeader title="Events" />
      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <ReactApexChart series={chartData || []} options={chartOptions} height={364} />
      </Box>
    </Card>
  );
}
