import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  questions: [],
  screens: [],
  total: 0,
  questionDetail: null,
  categories: [],
  profileGroups: [],
};

const slice = createSlice({
  name: 'question',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET questions
    getQuestionsSuccess(state, action) {
      state.isLoading = false;
      state.questions = action.payload.questions;
      state.total = action.payload.total;
    },

    // GET screens
    getScreensSuccess(state, action) {
      state.isLoading = false;
      state.screens = action.payload.screens;
    },

    // GET DETAIL USER
    getQuestionDetailSuccess(state, action) {
      state.isLoading = false;
      state.questionDetail = action.payload;
    },

    // GET categories
    getQuestionCategoriesSuccess(state, action) {
      state.isLoading = false;
      state.categories = action.payload;
    },

    // GET profile groups
    getProfileGroupsSuccess(state, action) {
      state.isLoading = false;
      state.profileGroups = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getQuestions({ ...params }) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/v2/question/get-list', {
        params,
      });
      dispatch(
        slice.actions.getQuestionsSuccess({
          questions: response.data?.data || [],
          total: response.data?.meta?.total || 0,
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getQuestionDetail(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/v1/admin/question/get-one/${id}`);
      dispatch(slice.actions.getQuestionDetailSuccess(response.data?.data || {}));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getQuestionCategories() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/v2/question/category/get-list');
      dispatch(slice.actions.getQuestionCategoriesSuccess(response.data?.data || []));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getProfileGroups() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const params = {
        type: 'profile_group',
        page: 1,
        page_size: 1000,
      };
      const response = await axios.get('/v1/metadata/get-list', {
        params,
      });
      dispatch(slice.actions.getProfileGroupsSuccess(response.data?.data || []));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getScreens() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/v1/metadata/get-list?type=question_category');
      dispatch(
        slice.actions.getScreensSuccess({
          screens: response.data?.data || [],
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function updateQuestion(data, id) {
  try {
    const response = await axios.put(`/v1/admin/question/update/${id}`, {
      ...data,
    });
    return response.data;
  } catch (error) {
    return error;
  }
}

export async function createQuestion(data) {
  try {
    const response = await axios.post(`/v1/admin/question/create`, {
      ...data,
    });
    return response.data;
  } catch (error) {
    return error;
  }
}

export async function updateAnswer(data, id) {
  try {
    const response = await axios.put(`/v1/admin/answer/update/${id}`, {
      ...data,
    });
    return response.data;
  } catch (error) {
    return error;
  }
}

export async function deleteAnswer(id) {
  try {
    const response = await axios.delete(`/v1/admin/answer/delete/${id}`);
    return response?.data;
  } catch (error) {
    return error?.error;
  }
}
