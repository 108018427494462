// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    dashboard: path(ROOTS_DASHBOARD, '/dashboard'),
    app: path(ROOTS_DASHBOARD, '/app'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/booking'),
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all'),
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    conversation: path(ROOTS_DASHBOARD, '/chat/:conversationKey'),
  },
  referral: {
    root: path(ROOTS_DASHBOARD, '/referral/management'),
    event: path(ROOTS_DASHBOARD, '/referral/event'),
    revenue: path(ROOTS_DASHBOARD, '/referral/revenue'),
    contracts: path(ROOTS_DASHBOARD, '/referral/contract'),
    createContracts: path(ROOTS_DASHBOARD, '/referral/contract/create'),
    detailContracts: path(ROOTS_DASHBOARD, '/referral/contract/:id'),
    entitlements: path(ROOTS_DASHBOARD, '/referral/entitlement'),
    createEntitlement: path(ROOTS_DASHBOARD, '/referral/entitlement/create'),
    detailEntitlement: path(ROOTS_DASHBOARD, '/referral/entitlement/:id'),
  },
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user/management'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    management: path(ROOTS_DASHBOARD, '/user/management'),
    detail: path(ROOTS_DASHBOARD, '/user/:id'),
    newUser: path(ROOTS_DASHBOARD, '/user/new'),
    editById: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
    account: path(ROOTS_DASHBOARD, '/user/account'),
  },
  role: {
    root: path(ROOTS_DASHBOARD, '/role/management'),
    create: path(ROOTS_DASHBOARD, '/role/create'),
    update: path(ROOTS_DASHBOARD, '/role/update'),
  },
  noti: {
    root: path(ROOTS_DASHBOARD, '/noti/management'),
    history: path(ROOTS_DASHBOARD, '/noti/history'),
    create: path(ROOTS_DASHBOARD, '/noti/create'),
    update: path(ROOTS_DASHBOARD, '/noti/update'),
  },
  feedback: {
    root: path(ROOTS_DASHBOARD, '/feedback/management'),
    create: path(ROOTS_DASHBOARD, '/feedback/create'),
    update: path(ROOTS_DASHBOARD, '/feedback/update'),
  },
  metaData: {
    root: path(ROOTS_DASHBOARD, '/meta-data/management/metadata_type'),
    referral: path(ROOTS_DASHBOARD, '/meta-data/management/referral_phone_number'),
    cheatphone: path(ROOTS_DASHBOARD, '/meta-data/management/cheat_phone_number'),
    funnel: path(ROOTS_DASHBOARD, '/meta-data/management/funnel_setting'),
    global: path(ROOTS_DASHBOARD, '/meta-data/management/global_setting'),
    prefix: path(ROOTS_DASHBOARD, '/meta-data/management/phone_prefix'),
    area: path(ROOTS_DASHBOARD, '/meta-data/management/ban_area_code'),
    swipe: path(ROOTS_DASHBOARD, '/meta-data/management/max_like_dislike'),
    create: path(ROOTS_DASHBOARD, '/meta-data/create'),
    update: path(ROOTS_DASHBOARD, '/meta-data/update'),
  },
  question: {
    root: path(ROOTS_DASHBOARD, '/question/management'),
    management: path(ROOTS_DASHBOARD, '/question/management'),
    detail: path(ROOTS_DASHBOARD, '/question/:id'),
    update: path(ROOTS_DASHBOARD, '/question/update/:id'),
    create: path(ROOTS_DASHBOARD, '/question/create'),
  },
  reportUser: {
    root: path(ROOTS_DASHBOARD, '/report-user/management'),
    detail: path(ROOTS_DASHBOARD, '/report-user/:id'),
    update: path(ROOTS_DASHBOARD, '/report-user/update/:id'),
  },
  blackList: {
    root: path(ROOTS_DASHBOARD, '/black-list/management'),
    detail: path(ROOTS_DASHBOARD, '/black-list/:id'),
    update: path(ROOTS_DASHBOARD, '/black-list/update/:id'),
  },
  report: {
    root: path(ROOTS_DASHBOARD, '/report/management'),
  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    product: path(ROOTS_DASHBOARD, '/e-commerce/product/:name'),
    productById: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    newProduct: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    editById: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    invoice: path(ROOTS_DASHBOARD, '/e-commerce/invoice'),
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    post: path(ROOTS_DASHBOARD, '/blog/post/:title'),
    postById: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
    newPost: path(ROOTS_DASHBOARD, '/blog/new-post'),
  },
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
