import PropTypes from 'prop-types';
// @mui
import {
    TextField,
    Grid, Autocomplete, Chip,
} from '@mui/material';

// ----------------------------------------------------------------------
const DATE_OPTION = [
  { id: '-event_timestamp', label: 'Latest' },
  { id: 'event_timestamp', label: 'Oldest' },
];

export const FILTER_EVENT_OPTION = [
    { id: '', label: 'REGISTER' },
    { id: '', label: 'CLICK' },
    { id: '', label: 'OPEN' },
    { id: '', label: 'INSTALL' },
];

RevenueListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  onDeleteUsers: PropTypes.func,
};

export default function RevenueListToolbar({  sort, setSort,
                                               eventsOption, filterEvent, setFilterEvent,
                                               entitlementOption, filterEntitlement, setFilterEntitlement,
                                               contractOption, filterContract, setFilterContract, defaultContract }) {
    return (
    <Grid container spacing={4} style={{ display: 'flex' }}>
      <Grid item xs={12} md={3}>
        <TextField
          select
          label="Sort"
          value={sort}
          fullWidth
          onChange={(e) => setSort(e.target.value)}
          SelectProps={{ native: true }}
          variant="outlined"
        >
          {DATE_OPTION.map((option) => (
            <option key={option.id} value={option.id}>
              {option.label}
            </option>
          ))}
        </TextField>
      </Grid>
    <Grid item xs={12} md={3}>
        <Autocomplete
            multiple
            inputlabelprops={{ shrink: true }}
            value={filterEvent}
            onChange={(e, newValue) => setFilterEvent(newValue)}
            options={eventsOption}
            getOptionLabel={(option) => option.name}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip key={option.id} size="small" label={option.name} {...getTagProps({ index })} />
                ))
            }
            renderInput={(params) => <TextField label="Events" {...params} />}
        />
    </Grid>

    <Grid item xs={12} md={3}>
        <Autocomplete
            multiple
            inputlabelprops={{ shrink: true }}
            value={filterEntitlement}
            onChange={(e, newValue) => setFilterEntitlement(newValue)}
            options={entitlementOption}
            getOptionLabel={(option) => option.name}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip key={option.id} size="small" label={option.name} {...getTagProps({ index })} />
                ))
            }
            renderInput={(params) => <TextField label="Type" {...params} />}
        />
    </Grid>
    <Grid item xs={12} md={3}>
        <Autocomplete
            multiple
            inputlabelprops={{ shrink: true }}
            value={filterContract}
            disabled={Boolean(defaultContract)}
            onChange={(e, newValue) => setFilterContract(newValue)}
            options={contractOption}
            getOptionLabel={(option) => option.phone_number}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip key={option.id} size="small" label={option.phone_number} {...getTagProps({ index })} />
                ))
            }
            renderInput={(params) => <TextField label="Contracts" {...params} />}
        />
    </Grid>
    </Grid>
  );
}
