import {createSlice} from '@reduxjs/toolkit';
// utils
import axios, {getBaseUrl} from '../../utils/axios';
//
import {dispatch} from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  notiList: [],
  notiInfoList: [],
  total: 0,
  totalPages: 1,
  deepLinks: [],
  totalDeepLink: 0,
  categories: [],
  totalCategories: 0,
  notiDetail: null,
  notiHistoryList: [],
  totalHistory: 0,
  metadata: null,
};

const slice = createSlice({
  name: 'noti',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    setNotiDetail(state, action) {
      state.notiDetail = action.payload;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET notiList
    getNotiListSuccess(state, action) {
      state.isLoading = false;
      state.notiList = action.payload.notiList;
      state.total = action.payload.total;
      state.totalPages = action.payload.totalPages;
    },

    // GET noti detail
    getNotiDetailSuccess(state, action) {
      state.isLoading = false;
      state.notiDetail = action.payload;
    },

    getNotiInfoListSuccess(state, action) {
        state.isLoading = false;
        state.notiInfoList = action.payload.notiInfoList;
        state.total = action.payload.total;
        state.totalPages = action.payload.totalPages;
        state.metadata = action.payload.metadata;
    },
    // GET deepLinks
    getDeepLinksSuccess(state, action) {
      state.isLoading = false;
      state.deepLinks = action.payload.deepLinks;
      state.totalDeepLink = action.payload.total;
    },

    // GET categories
    getCategoriesSuccess(state, action) {
      state.isLoading = false;
      state.categories = action.payload.categories;
      state.totalCategories = action.payload.total;
    },

    // GET notiList history
    getNotiHistoryListSuccess(state, action) {
      state.isLoading = false;
      state.notiHistoryList = action.payload.notiList;
      state.totalHistory = action.payload.total;
    },
  },
});

// Reducer
export default slice.reducer;
export const { setNotiDetail } = slice.actions;

// ----------------------------------------------------------------------

export function getNotiList({ ...params }) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/v1/admin/notify-rule/get-list', {
        params,
      });
      dispatch(
        slice.actions.getNotiListSuccess({
          notiList: response.data?.data || [],
          total: response.data?.meta?.total_rows || 0,
          totalPages: response.data?.meta?.total_pages || 1,
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getNotiInfoList({ ...params }) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/v1/admin/notify-info/get-list', {
        params,
      });
      dispatch(
        slice.actions.getNotiInfoListSuccess({
          notiInfoList: response.data?.data || [],
          total: response.data?.meta?.total || 0,
          totalPages: response.data?.meta?.total_pages || 1,
          metadata: response.data?.meta?.metadata || null,
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getNotiDetail(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/v1/admin/notify-rule/get-one/${id}`);
      dispatch(slice.actions.getNotiDetailSuccess(response.data?.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function sendNoti(data) {
  return axios.post(`/v1/admin/notification/send`, {
    ...data,
  });
}

export async function sendNotiRule(id) {
  return axios.get(`/v1/admin/notify-rule/send/${id}`);
}

export async function sendNotiByFile(data) {
  const token = localStorage.getItem('accessToken');
  return fetch(`${getBaseUrl()}/v1/admin/notification/send-by-file`, {
    method: 'POST',
    headers: {
      authorization: `Bearer ${token}`,
    },
    body: data,
  });
}

export async function createNoti(data) {
  try {
    return await axios.post(`/v1/admin/notify-rule/create`, {
      ...data,
    });
  } catch (error) {
    return error;
  }
}


export async function importNotiHistory(data) {
  try {
    return await axios.post(`/v1/admin/notify-history/create`, {
      ...data,
    });
  } catch (error) {
    return error;
  }
}

export async function updateNoti(data, id) {
  return axios.put(`/v1/admin/notify-rule/update/${id}`, {
    ...data,
  });
}

export async function deleteNoti(id) {
  return axios.delete(`/v1/admin/notify-rule/delete/${id}`);
}

export async function deleteAllNotihistory(id) {
  return axios.delete(`/v1/admin/notify-history/delete-all/${id}`);
}


export async function deleteNotiHistory(id) {
  return axios.delete(`/v1/admin/notify-history/delete/${id}`);
}

export function getDeepLinks(params) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/v1/metadata/get-list`, {
        params,
      });
      dispatch(
        slice.actions.getDeepLinksSuccess({
          deepLinks: response.data?.data || [],
          total: response.data?.meta?.total || 0,
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getKeyCategories(params) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/v1/metadata/get-list`, {
        params,
      });
      dispatch(
        slice.actions.getCategoriesSuccess({
          categories: response.data?.data || [],
          total: response.data?.meta?.total || 0,
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getNotiHistoryList({ ...params }) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/v1/admin/notify-history/get-list', {
        params,
      });
      dispatch(
        slice.actions.getNotiHistoryListSuccess({
          notiList: response.data?.data || [],
          total: response.data?.meta?.total || 0,
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
