import { capitalCase } from 'change-case';
import React, { useState } from 'react';
import {useSnackbar} from "notistack";

import {
  Container,
  Box,
  Tabs,
  Tab,
  Button,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  TextField
} from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import Page from '../../../components/Page';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
// sections
import Iconify from "../../../components/Iconify";
import {Entitlements} from '../../../sections/@dashboard/link/management';
import {createEntitlements} from '../../../redux/slices/entitlement';
// ----------------------------------------------------------------------
const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'key', label: 'Key', alignRight: false },
  { id: 'created_at', label: 'Created At', alignRight: false },
  { id: 'entitlement_details', label: 'Details', alignRight: false },
  { id: 'action', label: 'Action', alignRight: true },
];

export default function Entitlement() {
  const [dataChanged, setDataChanged] = useState(false);
  const [currentTab, setCurrentTab] = useState('entitlements');
  const [selectedCreateType, setSelectedCreateType] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const [name, setName] = useState('');
  const [key, setKey] = useState('');

  const handleChangeTab = (e, value) => {
    setCurrentTab(value);
  };

  const PROFILE_TABS = [
    {
      value: 'entitlements',
      component: <Entitlements tableHead={TABLE_HEAD} dataChanged={dataChanged}  setDataChanged={setDataChanged} defaultContract={""}/>,
    },
  ];

  const handleCloseCreateType = () => {
    setSelectedCreateType('');
  };

  const handleConfirmCreateType = async () => {
    if (!name) return enqueueSnackbar('Title is required. Make the link title easy to remember for you and your team.', { variant: 'warning' });
    if (!key) return enqueueSnackbar('Key is required.', { variant: 'warning' });

    const data = {
      name,
      key
    };

    const response = await  createEntitlements(data);
    if (response.status === 201) {
      enqueueSnackbar('Create link type success');
      handleCloseCreateType();
      setDataChanged(true)
    } else {
      enqueueSnackbar(response?.error?.detail ||  'Internal server error', { variant: 'error' });
    }
  };
  return (
      <Page title="Link: Types">
        <Container>
          <HeaderBreadcrumbs
              heading="Affiliate Types"
              links={[{ name: 'Types', href: PATH_DASHBOARD.referral.entitlements }, { name: 'List' }]}
              action={
                <Button
                    variant="contained"
                    startIcon={<Iconify icon="eva:plus-fill" />}
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedCreateType('open')
                    }}
                >
                  Create New
                </Button>
              }
         />
          <Tabs
              value={currentTab}
              scrollButtons="auto"
              variant="scrollable"
              allowScrollButtonsMobile
              sx={{ marginLeft: '24px' }}
              onChange={handleChangeTab}
          >
            {PROFILE_TABS.map((tab) => (
                <Tab disableRipple key={tab.value} value={tab.value} icon={tab.icon} label={capitalCase('Affiliate Type')} />
            ))}
          </Tabs>
          {PROFILE_TABS.map((tab) => {
            const isMatched = tab.value === currentTab;
            return isMatched && <Box key={tab.value}>{tab.component}</Box>;
          })}
        </Container>
        {selectedCreateType === 'open' && (
            <Dialog open={selectedCreateType === 'open'} fullWidth maxWidth="xs" onClose={handleCloseCreateType}>
              <DialogTitle >Create new referral type</DialogTitle>
              <DialogContent sx={{ mt: 3 }}>
                <TextField sx={{mt: 1}} fullWidth placeholder="Name" onChange={(e) => setName(e.target.value)} />
                <TextField sx={{mt: 1}} fullWidth placeholder="Key" onChange={(e) => setKey(e.target.value)} />
              </DialogContent>
              <DialogActions>
                <Button variant="contained" color="error" onClick={handleCloseCreateType}>
                  Cancel
                </Button>
                <Button variant="contained" onClick={handleConfirmCreateType}>
                  Confirm
                </Button>
              </DialogActions>
            </Dialog>
        )}
      </Page>
  );
}
