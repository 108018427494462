import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
// form
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import parsePhoneNumber from 'libphonenumber-js';
// @mui
import { Alert, Box, OutlinedInput, Stack, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// routes
import useAuth from '../../../hooks/useAuth';
import { PATH_DASHBOARD } from '../../../routes/paths';
import { ERROR_MESSAGE } from '../../../config';

// ----------------------------------------------------------------------

export default function VerifyCodeForm({ phoneNumber, setStep, setPhoneNumber }) {
  const navigate = useNavigate();
  const { genOTP, confirmOTP } = useAuth();
  const [disabled, setDisabled] = useState(true);

  const { enqueueSnackbar } = useSnackbar();

  const VerifyCodeSchema = Yup.object().shape({
    code1: Yup.string().required('Code is required'),
    code2: Yup.string().required('Code is required'),
    code3: Yup.string().required('Code is required'),
    code4: Yup.string().required('Code is required'),
    code5: Yup.string().required('Code is required'),
    code6: Yup.string().required('Code is required'),
  });

  const defaultValues = {
    code1: '',
    code2: '',
    code3: '',
    code4: '',
    code5: '',
    code6: '',
  };

  const {
    watch,
    control,
    setValue,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting, isValid },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(VerifyCodeSchema),
    defaultValues,
  });

  const values = watch();

  useEffect(() => {
    document.addEventListener('paste', handlePasteClipboard);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let count = 0;
    // eslint-disable-next-line no-restricted-syntax
    for (const [, value] of Object.entries(values)) {
      if (value) {
        count += 1;
      }
    }
    if (count === Object.keys(values).length) {
      setDisabled(false);
    }
  }, [values]);

  const onSubmit = async (data) => {
    try {
      // 679103
      const body = {
        otp: Object.values(data).join(''),
        phone_number: phoneNumber,
        device_id: 'web',
        device_token: 'web',
        platform: 'admin_portal',
      };
      const response = await confirmOTP(body);
      if (response?.data?.status === 'created' && response?.data?.token) {
        enqueueSnackbar(`Verified success`);
        navigate(PATH_DASHBOARD.user.root, { replace: true });
      } else {
        setError('afterSubmit', response?.error?.detail || ERROR_MESSAGE);
      }
    } catch (error) {
      console.error(error);
      setError('afterSubmit', error);
    }
  };

  const handlePasteClipboard = (event) => {
    let data = event?.clipboardData?.getData('Text') || '';

    data = data.split('');

    [].forEach.call(document.querySelectorAll('#field-code'), (node, index) => {
      node.value = data[index];
      const fieldIndex = `code${index + 1}`;
      setValue(fieldIndex, data[index]);
    });
  };

  const handleChangeWithNextField = (event, handleChange) => {
    const { maxLength, value, name } = event.target;
    const fieldIndex = name.replace('code', '');

    const fieldIntIndex = Number(fieldIndex);
    const numberRegex = /^\d+$/;
    if (value === '') {
      const prevfield = document.querySelector(`input[name=code${fieldIntIndex - 1}]`);

      if (prevfield !== null) {
        prevfield.focus();
      }
      handleChange(event);
    } else {
      if (!numberRegex.test(value)) return;
      if (value.length >= maxLength) {
        if (fieldIntIndex < 6) {
          const nextfield = document.querySelector(`input[name=code${fieldIntIndex + 1}]`);

          if (nextfield !== null) {
            nextfield.focus();
          }
        }
      }

      handleChange(event);
    }
  };

  const handleResendOTP = () => genOTP(phoneNumber);

  const handleBackToEnterPhone = () => {
    const parsePhone = parsePhoneNumber(phoneNumber);
    setPhoneNumber(parsePhone.nationalNumber);
    setStep(1);
  };

  return (
    <>
      {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.error.detail || ERROR_MESSAGE}</Alert>}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack direction="row" spacing={2} justifyContent="center">
          {Object.keys(values).map((name, index) => (
            <Controller
              key={name}
              name={`code${index + 1}`}
              control={control}
              render={({ field }) => (
                <OutlinedInput
                  {...field}
                  id="field-code"
                  autoFocus={index === 0}
                  placeholder="-"
                  onChange={(event) => handleChangeWithNextField(event, field.onChange)}
                  inputProps={{
                    maxLength: 1,
                    sx: {
                      p: 0,
                      textAlign: 'center',
                      width: { xs: 36, sm: 56 },
                      height: { xs: 36, sm: 56 },
                    },
                  }}
                />
              )}
            />
          ))}
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          disabled={!isValid || disabled}
          sx={{ mt: 3 }}
        >
          Verify
        </LoadingButton>
        <Box display="flex" justifyContent="space-between">
          <Button onClick={handleBackToEnterPhone}>Change Phone Number</Button>
          <Button onClick={handleResendOTP}>Resend OTP</Button>
        </Box>
      </form>
    </>
  );
}
