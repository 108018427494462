import { createSlice, current } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

function objFromArray(array, key = 'id') {
  return array.reduce((accumulator, current) => {
    accumulator[current[key]] = current;
    return accumulator;
  }, {});
}

const initialState = {
  isLoading: false,
  isLoadMore: false,
  error: null,
  activeConversationId: null,
  participants: [],
  recipients: [],
  allConversations: [],
  conversations: [],
  users: [],
  searhUsers: [],
  activeConversation: null,
  totalUnread: 0,
  page: 1,
  userPage: 1,
  totalUserPage: 2,
  totalPage: 1,
  pageSize: 50,
  userPageSize: 50,
};

const slice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    startLoadMore(state) {
      state.isLoadMore = true;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET CONTACT SSUCCESS
    getContactsSuccess(state, action) {
      const users = action.payload;

      state.users = users;
    },

    // GET CONTACT SSUCCESS
    getUnreadSuccess(state, action) {
      state.totalUnread = action.payload;
    },

    // GET CONVERSATIONS
    getConversationsSuccess(state, action) {
      const conversations = action.payload;
      console.log(conversations)
      state.allConversations = conversations.data;
      state.conversations = conversations.data;
      state.totalPage = conversations.meta.total_pages;
      // state.conversations.allIds = Object.keys(state.conversations.byId);
    },  // GET CONVERSATIONS

    getActiveConversationSuccess(state, action) {
      const conversation = action.payload;

      state.conversation = conversation;
      state.activeConversationId = conversation.conversation_id;
    },

    loadMoreConversations(state, action) {
      const data = action.payload;

      const newConversations = data.conversations;
      const newPage = data.page;
      const currentState = current(state)
      const conversations = currentState.allConversations;
      if (newConversations.length === 0) {
        state.page = newPage;
      } else {
        state.allConversations = [...conversations, ...newConversations];
        state.conversations = [...conversations, ...newConversations];
        state.page += 1;
      }
      state.isLoadMore = false;
    },

    addConversationSuccess(state, action) {
      const data = action.payload;

      const conversation = data;
      const currentState = current(state)
      const conversations = currentState.allConversations;
      const foundIndex = conversations.findIndex(item => item.conversation_id === conversation.conversation_id);

      if (foundIndex > -1) {


        const updatedList = conversations.map(item => (item.conversation_id === conversation.conversation_id ? conversation : item));


        state.allConversations = updatedList
        state.conversations = updatedList
      } else {
        state.allConversations = [...conversations, conversation];
        state.conversations = [...conversations, conversation];

      }

    },

    replaceConversationSuccess(state, action) {
      const data = action.payload;

      const conversation = data;
      const currentState = current(state)
      const conversations = currentState.allConversations;
      const foundIndex = conversations.findIndex(item => item.conversation_id === conversation.conversation_id);
      if (foundIndex > -1) {
        const updatedList = conversations.map(item => (item.conversation_id === conversation.conversation_id ? conversation : item));

        state.allConversations = updatedList
        state.conversations = updatedList
      } else {
        state.allConversations = [...conversations, conversation];
        state.conversations = [...conversations, conversation];

      }

    },
    insertConversationSuccess(state, action) {
      const data = action.payload;

      const conversation = data;
      const currentState = current(state)
      const conversations = currentState.allConversations;
      const foundIndex = conversations.findIndex(item => item.conversation_id === conversation.conversation_id);
      if (foundIndex > -1) {
        const updatedList = conversations.map(item => (item.conversation_id === conversation.conversation_id ? conversation : item));
        updatedList.splice(foundIndex, 1);

        state.allConversations = [conversation, ...updatedList]
        state.conversations = [conversation, ...updatedList]
      } else {
        state.allConversations = [conversation, ...conversations];
        state.conversations = [conversation, ...conversations];
      }

    },
    // ON SEND MESSAGE
    onSendMessage(state, action) {
      const conversation = action.payload;
      const { conversationId, messageId, message, contentType, attachments, createdAt, senderId } = conversation;

      const newMessage = {
        id: messageId,
        body: message,
        contentType,
        attachments,
        createdAt,
        senderId,
      };

      // state.conversations.byId[conversationId].messages.push(newMessage);
    },

    // searchCoversations(state, action) {
    //   const keyword = action.payload;
    //   const currentState = current(state)
    //   const conversations = currentState.allConversations;
    //   if (keyword) {
    //     const filterConversations = conversations.filter(conversation =>
    //       conversation.first_name.trim().toLowerCase().includes(keyword.trim().toLowerCase())
    //     );
    //     state.conversations = filterConversations;
    //   } else {
    //     state.conversations = conversations;
    //   }
    // },

    reorderConversations(state, action) {
      const reorderedConversations = action.payload;
      state.conversations = reorderedConversations;
      state.allConversations = reorderedConversations;
    },

    loadMoreSearchsContactsSuccess(state, action) {
      const data = action.payload;
      const newUsers = data.users;
      const currentState = current(state)
      const users = currentState.users;
      const totalUserPage = data.totalPage;
      if (newUsers.length === 0 || currentState.userPage >= totalUserPage) {
        state.userPage = totalUserPage;
        state.totalUserPage = totalUserPage;
      } else {
        state.users = [...users, ...newUsers];
        state.totalUserPage = totalUserPage;
        state.userPage += 1;
      }
    },
    resetContactsSuccess(state, action) {
      state.userPage = 0;
      state.totalUserPage = 2;
    },
    markConversationAsReadSuccess(state, action) {

    },

    // GET PARTICIPANTS
    getParticipantsSuccess(state, action) {
      const participants = action.payload;
      state.participants = participants;
    },

    // RESET ACTIVE CONVERSATION
    setActiveConversation(state, action) {
      const conversationId = action.payload;
      state.activeConversationId = conversationId;
    },

    resetActiveConversation(state) {
      state.activeConversationId = null;
    },


    addRecipients(state, action) {
      const recipients = action.payload;
      state.recipients = recipients;
    },


  },
});

// Reducer
export default slice.reducer;

export const selectChatState = (state) => state.parent;


// Actions
export const { addRecipients, onSendMessage, resetActiveConversation, setActiveConversation } = slice.actions;

// ----------------------------------------------------------------------


export function getContacts() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {

      const page = slice.getInitialState().userPage
      const pageSize = slice.getInitialState().userPageSize
      const queryParams = {
        'page': page,
        'page_size': pageSize,
      };

      const response = await axios.get('v1/user/get-list', { params: queryParams });

      const users = response.data.data;


      dispatch(slice.actions.getContactsSuccess(users));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function searchContacts(keyword, userPage, userPageSize) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {


      const queryParams = {
        'page': userPage,
        'page_size': userPageSize,
        'search': keyword,
      };

      const response = await axios.get('v1/user/get-list', { params: queryParams });

      const users = response.data.data;


      dispatch(slice.actions.getContactsSuccess(users));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function loadMoreSearchContacts(keyword, userPage, userPageSize, totalUserPage) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {

      const queryParams = {
        'page': userPage,
        'page_size': userPageSize,
        'search': keyword,
      };

      const response = await axios.get('v1/user/get-list', { params: queryParams });
      const data = response.data;
      const users = data.data;
      const totalPage = data.meta.total_pages

      const newPage = data.meta.page


      dispatch(slice.actions.loadMoreSearchsContactsSuccess({
        'users': users,
        'totalPage': totalPage,
        'page': newPage,
      }));

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}



// ----------------------------------------------------------------------

export function resetContacts() {
  return async () => {
    dispatch(slice.actions.resetContactsSuccess());
  };
}

// ----------------------------------------------------------------------

export function getConversations() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const page = slice.getInitialState().page
      const pageSize = slice.getInitialState().pageSize
      const queryParams = {
        'page': page,
        'page_size': pageSize,
      };

      const response = await axios.get('v1/admin/support-conversation/get-list', { params: queryParams });

      const conversations = response.data;
      console.log('conversations', conversations)
      if (conversations && conversations.data.length > 0) {
        dispatch(slice.actions.getConversationsSuccess(conversations));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


// ----------------------------------------------------------------------

export function searchCoversations(keyword) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {

      const page = slice.getInitialState().page
      const pageSize = slice.getInitialState().pageSize
      const queryParams = {
        'page': page,
        'page_size': pageSize,
        ...(keyword && { 'search': keyword })
      };

      const response = await axios.get('v1/admin/support-conversation/get-list', { params: queryParams });

      const conversations = response.data;
      console.log('conversations', conversations)
      if (conversations && conversations.data.length > 0) {
        dispatch(slice.actions.getConversationsSuccess(conversations));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getConversation(conversationKey) {
  return async () => {
    try {
      const response = await axios.get('/api/chat/conversation', {
        params: { conversationKey },
      });
      dispatch(slice.actions.getConversationSuccess(response.data.conversation));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


// ----------------------------------------------------------------------

export function getUnreadSupportMessages() {
  return async () => {
    try {
      const response = await axios.get('v1/admin/support-conversation/get-unread-message');
      const unread = response.data.data;
      dispatch(slice.actions.getUnreadSuccess(unread));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function addConversationMessage(message, conversation) {
  // message.message 

  return async () => {

    try {

      const newConversation = {
        'conversation_id': conversation.conversation_id,
        'user_id': conversation.user_id,
        'first_name': conversation.first_name,
        'avatar': conversation.avatar,
        'last_message_time': message.created_at,
        'content_message': message.content,
      };

      dispatch(slice.actions.insertConversationSuccess(newConversation));


    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };


}

export function handleSetActiveConversation(conversationId, allConversations) {
  return async () => {
    try {
      const foundIndex = allConversations.findIndex(item => item.conversation_id === conversationId);

      if (foundIndex > -1) {

        const conversation = allConversations[foundIndex];

        const newConversation = {
          'conversation_id': conversation.conversation_id,
          'user_id': conversation.user_id,
          'first_name': conversation.first_name,
          'avatar': conversation.avatar,
          'last_message_time': conversation.last_message_time,
          'content_message': conversation.content_message,
          'has_read': false,
        };

        dispatch(slice.actions.replaceConversationSuccess(newConversation));
      }

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function handleConversationSocketEvent(socketEvent, allConversations, activeConversationId) {
  return async () => {

    try {

      if (socketEvent.event_name !== 'chat') {
        return;
      }

      const body = socketEvent.event_payload.body

      const conversationId = body.conversation_id

      const createdAt = body.created_at

      const content = body.body.content

      const foundIndex = allConversations.findIndex(item => item.conversation_id === conversationId);

      let conversation = null
      if (foundIndex > -1) {

        conversation = allConversations[foundIndex];

        conversation = {
          'conversation_id': conversation.conversation_id,
          'user_id': conversation.user_id,
          'first_name': conversation.first_name,
          'avatar': conversation.avatar,
          'last_message_time': createdAt,
          'content_message': content,
          'has_read': activeConversationId !== conversationId,
        };

        dispatch(slice.actions.insertConversationSuccess(conversation));
      } else {
        const queryParams = {
          'conversation_id': conversationId,
          'sort': '-created_at',
          'page': 1,
          'page_size': 1,
        };

        const response = await axios.get(`v1/portal/message/get-list`, { params: queryParams });

        const data = response.data

        const meta = data.meta.metadata;

        conversation = {
          'conversation_id': conversationId,
          'user_id': meta.id,
          'first_name': meta.name,
          'avatar': meta.avatar,
          'last_message_time': createdAt,
          'content_message': content,
          'has_read': activeConversationId !== conversationId,
        };

        dispatch(slice.actions.insertConversationSuccess(conversation));
      }


    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


// ----------------------------------------------------------------------


export function reorderConversations(sort) {
  return async () => {
    try {
      const queryParams = sort ? { 'sort': sort } : {};
      const response = await axios.get('v1/admin/support-conversation/get-list', { params: queryParams });
      const reorderedConversations = response.data.data;
      dispatch(slice.actions.reorderConversations(reorderedConversations));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function handleLoadMoreConversations(page, pageSize, onPageChange, keyword) {
  return async () => {
    dispatch(slice.actions.startLoadMore());
    try {

      const queryParams = {
        'page': page + 1,
        'page_size': pageSize,
        ...(keyword && { 'search': keyword })
      };

      const response = await axios.get('v1/admin/support-conversation/get-list', { params: queryParams });


      const data = response.data

      const conversations = data.data;

      const totalPage = data.meta.total_pages

      const newPage = data.meta.page

      onPageChange(newPage)

      dispatch(slice.actions.loadMoreConversations({
        'conversations': conversations,
        'totalPage': totalPage,
        'page': newPage,
      }));


    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

