// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgIconStyle from '../../../components/SvgIconStyle';
import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking'),
  read: getIcon('ic_read'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'general',
  //   items: [
  //     { title: 'app', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },
  //     { title: 'e-commerce', path: PATH_DASHBOARD.general.ecommerce, icon: ICONS.ecommerce },
  //     { title: 'analytics', path: PATH_DASHBOARD.general.analytics, icon: ICONS.analytics },
  //     { title: 'banking', path: PATH_DASHBOARD.general.banking, icon: ICONS.banking },
  //     { title: 'booking', path: PATH_DASHBOARD.general.booking, icon: ICONS.booking },
  //   ],
  // },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'management',
    items: [
      {
        title: 'Dashboard',
        path: PATH_DASHBOARD.general.dashboard,
        icon: <Iconify icon="carbon:report" />,
      },
      // MANAGEMENT : USER
      {
        title: 'user',
        path: PATH_DASHBOARD.user.root,
        icon: ICONS.user,
        // children: [
        //   { title: 'User Management', path: 'user' },
        //   // { title: 'User Management', path: PATH_DASHBOARD.user.profile },
        //   // { title: 'cards', path: PATH_DASHBOARD.user.cards },
        //   // { title: 'create', path: PATH_DASHBOARD.user.newUser },
        //   // { title: 'edit', path: PATH_DASHBOARD.user.editById },
        //   // { title: 'account', path: PATH_DASHBOARD.user.account },
        // ],
      },
      {
        title: 'Reported',
        path: PATH_DASHBOARD.reportUser.root,
        icon: <Iconify icon="eva:person-delete-fill" />,
      },
      {
        title: 'Banned',
        path: PATH_DASHBOARD.blackList.root,
        icon: <Iconify icon="fluent-emoji-high-contrast:face-with-head-bandage" />,
      },
      {
        title: 'Role',
        path: PATH_DASHBOARD.role.root,
        icon: <Iconify icon="carbon:user-identification" />,
      },
      {
        title: 'Notification',
        path: PATH_DASHBOARD.noti.root,
        icon: <Iconify icon="carbon:notification-new" />,
      },
      {
        title: 'Survey',
        path: PATH_DASHBOARD.feedback.root,
        icon: <Iconify icon="uil:feedback" />,
      },
      // {
      //   title: 'Notification history',
      //   path: PATH_DASHBOARD.noti.history,
      //   icon: <Iconify icon="icon-park-outline:history-query" />,
      // },
      {
        title: 'Question',
        path: PATH_DASHBOARD.question.root,
        icon: ICONS.kanban,
      },
      {
        title: 'Metadata',
        path: PATH_DASHBOARD.metaData.root,
        icon: <Iconify icon="eva:settings-2-fill" />,
        children: [
          { title: 'main', path: PATH_DASHBOARD.metaData.root },
          { title: 'referral whitelist', path: PATH_DASHBOARD.metaData.referral },
          { title: 'cheat phone number', path: PATH_DASHBOARD.metaData.cheatphone},
          { title: 'funnel setting', path: PATH_DASHBOARD.metaData.funnel},
          { title: 'global setting', path: PATH_DASHBOARD.metaData.global },
          { title: 'phone prefix setting', path: PATH_DASHBOARD.metaData.prefix },
          { title: 'ban area setting', path: PATH_DASHBOARD.metaData.area },
          { title: 'like/dislike setting', path: PATH_DASHBOARD.metaData.swipe },
        ],
      },

      // MANAGEMENT : E-COMMERCE
      // {
      //   title: 'e-commerce',
      //   path: PATH_DASHBOARD.eCommerce.root,
      //   icon: ICONS.cart,
      //   children: [
      //     { title: 'shop', path: PATH_DASHBOARD.eCommerce.shop },
      //     { title: 'product', path: PATH_DASHBOARD.eCommerce.productById },
      //     { title: 'list', path: PATH_DASHBOARD.eCommerce.list },
      //     { title: 'create', path: PATH_DASHBOARD.eCommerce.newProduct },
      //     { title: 'edit', path: PATH_DASHBOARD.eCommerce.editById },
      //     { title: 'checkout', path: PATH_DASHBOARD.eCommerce.checkout },
      //     { title: 'invoice', path: PATH_DASHBOARD.eCommerce.invoice },
      //   ],
      // },

      // MANAGEMENT : BLOG
      // {
      //   title: 'blog',
      //   path: PATH_DASHBOARD.blog.root,
      //   icon: ICONS.blog,
      //   children: [
      //     { title: 'posts', path: PATH_DASHBOARD.blog.posts },
      //     { title: 'post', path: PATH_DASHBOARD.blog.postById },
      //     { title: 'new post', path: PATH_DASHBOARD.blog.newPost },
      //   ],
      // },
    ],
  },

  // APP
  // ----------------------------------------------------------------------
  {
    subheader: 'customer service',
    items: [
      // {
      //   title: 'mail',
      //   path: PATH_DASHBOARD.mail.root,
      //   icon: ICONS.mail,
      //   info: (
      //     <Label variant="outlined" color="error">
      //       +32
      //     </Label>
      //   ),
      // },
      { title: 'chat', path: PATH_DASHBOARD.chat.root, icon: ICONS.chat },
      // { title: 'calendar', path: PATH_DASHBOARD.calendar, icon: ICONS.calendar },
      // {
      //   title: 'kanban',
      //   path: PATH_DASHBOARD.kanban,
      //   icon: ICONS.kanban,
      // },
    ],
  },
  {
    subheader: 'Affiliate Program',
    items: [
      // { title: 'Event', path: PATH_DASHBOARD.referral.event, icon: <Iconify icon="mdi:chart-line" /> },
      { title: 'Affiliates', path: PATH_DASHBOARD.referral.contracts, icon: <Iconify icon="clarity:contract-line" /> },
      { title: 'Event', path: PATH_DASHBOARD.referral.event, icon: <Iconify icon="tabler:affiliate" /> },
      { title: 'Income', path: PATH_DASHBOARD.referral.revenue, icon: ICONS.analytics },
      { title: 'Type', path: PATH_DASHBOARD.referral.entitlements, icon: <Iconify icon="streamline:subscription-cashflow" /> },
      { title: 'Link', path: PATH_DASHBOARD.referral.root, icon: <Iconify icon="ri:links-fill" /> },
    ],
  },
];

export default navConfig;
