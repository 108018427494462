import { useEffect, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import {
    Card,
    Table,
    TableRow,
    TableBody,
    TableCell,
    Container,
    TableContainer,
    TablePagination,
    Tooltip,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Avatar,
    Box,
    Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import moment from "moment";
import Loading from '../../../../components/Loading';
import {deleteHardLink, deleteLink, getLinks} from '../../../../redux/slices/link';
// components
import Page from '../../../../components/Page';
import Scrollbar from '../../../../components/Scrollbar';
import SearchNotFound from '../../../../components/SearchNotFound';
// sections
import { LinkListHead, LinkListToolbar } from '../list';
import {ERROR_MESSAGE, SEARCH_TIMEOUT} from '../../../../config';
import Label from "../../../../components/Label";
import Iconify from "../../../../components/Iconify";
// ----------------------------------------------------------------------

export default function DeletedLinks({ tableHead, dataChanged,setDataChanged }) {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const theme = useTheme();
    const dispatch = useDispatch();
    const {links, total, isLoading } = useSelector((state) => state.link);
    const [search, setSearch] = useState('');
    const [firstTime, setFirstTime] = useState(true);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [sort, setSort] = useState('-created_at');
    const [selectedDeleteLink, setSelectedDeleteLink] = useState(null);

    const fetchLinks = (page = 1, page_size = 10, sort = '-created_at', search = '',  is_deleted = true,) => {
        // Extract the 'id' property from each object in filterSub
        // Join the subscriptionIds array into a comma-separated string
        const params = {
            page,
            page_size,
            sort,
            search,
            is_deleted,
        };
        dispatch(getLinks(params));
    };

    useEffect(() => {
        if (!firstTime) {
            fetchLinks(page, pageSize, sort, search,true);
        }
    }, [page]);

    useEffect(() => {
        if (!firstTime) {
            fetchLinks(1, pageSize, sort, search,true);
        }
    }, [pageSize, sort]);

    useEffect(() => {
        if (!firstTime) {
            setTimeout(() => {
                fetchLinks(1, pageSize, sort, search, true);
            }, SEARCH_TIMEOUT);
        }
    }, [search]);

    useEffect(() => {
        if (firstTime) {
            fetchLinks();
        }
        setFirstTime(false);
    }, []);


    useEffect(() => {
        if (dataChanged) {
            fetchLinks();
        }
        setDataChanged(false);
    }, [dataChanged]);

    const handleChangeRowsPerPage = (event) => {
        setPageSize(parseInt(event.target.value, 10));
        setPage(1);
    };

    const handleFilterByName = (text) => {
        setSearch(text);
        setPage(1);
    };

    const handleCloseDeleteLink = () => {
        setSelectedDeleteLink(null);
    };

    const handleConfirmDeleteLink = async () => {
        const response = await deleteHardLink(selectedDeleteLink.id);
        if (response.status === 200) {
            fetchLinks();
            enqueueSnackbar('Link permanently deleted');
            handleCloseDeleteLink();
        } else {
            enqueueSnackbar(response?.error?.detail || ERROR_MESSAGE, { variant: 'error' });
        }
    };

    return (
        <Page title="Link: List">
            {isLoading && <Loading />}
            <Container>
                <Card sx={{ p: 2, my: 2 }}>
                    <LinkListToolbar
                        filterName={search}
                        onFilterName={handleFilterByName}
                        sort={sort}
                        setSort={setSort}
                    />
                </Card>
                <Card sx={{ p: 2, my: 2 }}>
                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                <LinkListHead
                                    headLabel={tableHead}
                                    rowCount={links.length}
                                />
                                <TableBody>
                                    {links.map((row) => {
                                        const {
                                            id,
                                            url,
                                            type,
                                            referral_code,
                                            referral_history,
                                            created_at,
                                            deleted_at,
                                            children,
                                            json_value,
                                          user,
                                        } = row;
                                        return (
                                            <TableRow
                                                hover
                                                key={id}
                                                tabIndex={-1}
                                                role="checkbox"
                                                onClick={() => navigate(`/referral/${id}`, { state: { page } })}
                                                // selected={isItemSelected}
                                                // aria-checked={isItemSelected}
                                                style={{ backgroundColor: deleted_at ? '#f2f2f2' : 'inherit' }}
                                            >
                                                {/* <TableCell padding="checkbox">
                          <Checkbox checked={isItemSelected} onClick={() => handleClick(name)} />
                        </TableCell> */}

                                                <TableCell align="left" style={{ verticalAlign: 'top' }}>
                                                    {json_value?.data?.$marketing_title || ''}
                                                </TableCell>
                                                <TableCell
                                                    sx={{ 
                                                        display: 'flex', 
                                                        verticalAlign: 'top',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        whiteSpace: 'nowrap',
                                                        maxWidth: 200, // Adjust this value as needed
                                                    }}
                                                    style={{ verticalAlign: 'top' }}
                                                    >
                                                    {url}
                                                </TableCell>
                                                <TableCell align="left" style={{ verticalAlign: 'top' }}>
                                                    {referral_code || ''}
                                                </TableCell>
                                                <TableCell align="left" style={{ verticalAlign: 'top',  }}>
                                                    { user && (
                                                      <TableCell
                                                        sx={{ display: 'flex', alignItems: 'center' }}
                                                        onClick={() => navigate(`/user/${user?.id}`)}
                                                      >
                                                          <Avatar alt={user?.first_name} src={user?.avatar?.url?.thumbnail || ''} sx={{ mr: 2 }} />
                                                          <Box>
                                                              <Typography
                                                                variant="subtitle2"
                                                                onClick={() => navigate(`/user/${user?.id}`)}
                                                                noWrap
                                                                color={theme.palette.primary.main}
                                                                sx={{ cursor: 'pointer' }}
                                                              >
                                                                  {user?.first_name}
                                                              </Typography>
                                                              <Typography variant="caption" noWrap>
                                                                  {user?.phone_number}
                                                              </Typography>
                                                              <Typography variant="caption" noWrap>
                                                                  {user?.email}
                                                              </Typography>
                                                          </Box>
                                                      </TableCell>
                                                    )}
                                                </TableCell>
                                                <TableCell align="left" style={{ verticalAlign: 'top' }}>
                                                    {created_at ? moment(created_at).format('DD/MM/YYYY - HH:mm') : 'Unknown'}
                                                </TableCell>
                                                <TableCell align="left" style={{ verticalAlign: 'top' }}>
                                                    {deleted_at ? moment(deleted_at).format('DD/MM/YYYY - HH:mm') : ''}
                                                </TableCell>
                                                <TableCell align="left" style={{ verticalAlign: 'top' }}>
                                                    <Label
                                                        sx={{ mr: 1, mb: 1 }}
                                                        key={type}
                                                        variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                                                        color={
                                                            (type === 'internal' && 'primary') ||
                                                            (type === 'external' && 'secondary') ||
                                                            'error'
                                                        }
                                                    >
                                                        {type}
                                                    </Label>
                                                </TableCell>
                                                <TableCell align="left" style={{ verticalAlign: 'top' }}>
                                                    {referral_history.length > 0 ? (
                                                        <div style={{ display: 'grid'}}>
                                                            {referral_history.map((historyItem, index) => (
                                                                <Card
                                                                    key={historyItem.event}
                                                                    variant="outlined"
                                                                    sx={{
                                                                        pl: 1,
                                                                        color:
                                                                            (historyItem.event === 'OPEN' && 'error') ||
                                                                            (historyItem.event === 'CLICK' && theme.palette.error.dark) ||
                                                                            (historyItem.event === 'INSTALL' && theme.palette.success.dark) ||
                                                                            (historyItem.event === 'REGISTER' && theme.palette.info.dark) || 'success'
                                                                            ,
                                                                        border: '1px solid #ccc',
                                                                        borderRadius: '4px',
                                                                        mb: 1,
                                                                    }}
                                                                >
                                                                        <strong >{historyItem.event}</strong>: {historyItem.count}
                                                                </Card>
                                                            ))}
                                                        </div>
                                                    ) : (
                                                        <div>No referral history</div>
                                                    )}
                                                </TableCell>
                                                <TableCell align="right">
                                                    <Tooltip title="Delete">
                                                        <IconButton
                                                            size="medium"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                setSelectedDeleteLink(row);
                                                            }}
                                                        >
                                                            <Iconify icon={'typcn:delete'} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                                {!links.length && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                <SearchNotFound searchQuery={search} />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>

                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={total}
                        rowsPerPage={pageSize}
                        page={page - 1}
                        onPageChange={(e, page) => setPage(page + 1)}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
            </Container>
            {selectedDeleteLink && (
                <Dialog open={Boolean(selectedDeleteLink)} fullWidth maxWidth="xs" onClose={handleCloseDeleteLink}>
                    <DialogTitle >Hard delete Link: {selectedDeleteLink.url}</DialogTitle>
                    <DialogActions>
                        <Button variant="contained" color="error" onClick={handleCloseDeleteLink}>
                            Cancel
                        </Button>
                        <Button variant="contained" onClick={handleConfirmDeleteLink}>
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </Page>
    );
}
