// WebSocketService.js
import { createContext, useContext, useEffect, useState } from 'react';
import { webSocket } from "rxjs/webSocket";

const getWebSocketURL = () => {
  return process.env.REACT_APP_NODE_ENV === 'development'
    ? 'wss://event.noormatch.app/ws'
    : 'wss://event.myjoon.app/ws';
};


const WebSocketContext = createContext();

export function useWebSocket() {
  return useContext(WebSocketContext);
}
export function WebsocketProvider({ children }) {
  const [socketSubject, setSocketSubject] = useState(null);

  const [subscription, setSubscription] = useState(null);

  useEffect(() => {

    const accessToken = window.localStorage.getItem('accessToken');

    function connect() {
      const socket = webSocket(getWebSocketURL());

      setSocketSubject(socket);

      if (accessToken != null) {
        socket.next({ 'topic': 'is_online', 'token': accessToken, 'platform': 'web' });
      }

      setInterval(() => {
        socket.next('ping');
      }, 30000);

      const newSubscription = socket.subscribe({
        next: () => { },
        error: (error) => {
          console.error('WebSocket error:', error);
          setTimeout(() => {
            socket.complete();
            connect();
          }, 3000); //
        },
        complete: () => { },
      });


      setSubscription(newSubscription)
    }

    connect();


    return () => {
      subscription?.unsubscribe();
      socketSubject?.unsubscribe();
    };

  }, [getWebSocketURL()]);

  const sendMessage = (message) => {
    if (socketSubject) {
      socketSubject.next(message);
    }
  };

  const contextValue = {
    socketSubject,
    sendMessage,
  };

  return (
    <WebSocketContext.Provider value={contextValue}>
      {children}
    </WebSocketContext.Provider>
  );
}