import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';
import { PATH_DASHBOARD } from './paths';
import LinkManagement from "../pages/dashboard/link/LinkManagement";
import DetailLink from "../pages/dashboard/link/Detail";
import LinkRevenue from "../pages/dashboard/link/LinkRevenue";
import Entitlement from "../pages/dashboard/entitlement/Entitlement";
import Contract from "../pages/dashboard/contract/Contract";
import DetailContract from "../pages/dashboard/contract/Detail";
import DetailEntitlement from "../pages/dashboard/entitlement/Detail";
import TypeDetailEntitlement from "../pages/dashboard/entitlement/TypeDetail";
import LinkEvent from "../pages/dashboard/link/LinkEvent";
import ReportDashboard from '../pages/dashboard/report/ReportDashboard';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
      ],
    },

    // Dashboard Routes
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: '/', element: <Navigate to="/dashboard" replace />, index: true },
        { path: 'dashboard', element: <ReportDashboard /> },
        // { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'app', element: <GeneralApp /> },
        { path: 'ecommerce', element: <GeneralEcommerce /> },
        { path: 'analytics', element: <GeneralAnalytics /> },
        { path: 'banking', element: <GeneralBanking /> },
        { path: 'booking', element: <GeneralBooking /> },
        {
          path: 'e-commerce',
          children: [
            { element: <Navigate to="/dashboard/e-commerce/shop" replace />, index: true },
            { path: 'shop', element: <EcommerceShop /> },
            { path: 'product/:name', element: <EcommerceProductDetails /> },
            { path: 'list', element: <EcommerceProductList /> },
            { path: 'product/new', element: <EcommerceProductCreate /> },
            { path: 'product/:name/edit', element: <EcommerceProductCreate /> },
            { path: 'checkout', element: <EcommerceCheckout /> },
            { path: 'invoice', element: <EcommerceInvoice /> },
          ],
        },
        {
          path: 'user',
          children: [
            { element: <Navigate to={PATH_DASHBOARD.user.management} replace />, index: true },
            // { path: 'profile', element: <UserProfile /> },
            // { path: 'cards', element: <UserCards /> },
            { path: 'management', element: <UserManagement /> },
            { path: ':id', element: <DetailUser /> },
            // { path: 'new', element: <UserCreate /> },
            // { path: ':name/edit', element: <UserCreate /> },
            // { path: 'account', element: <UserAccount /> },
          ],
        },
        {
          path: 'question',
          children: [
            { element: <Navigate to={PATH_DASHBOARD.question.management} replace />, index: true },
            { path: 'management', element: <QuestionManagement /> },
            { path: 'update/:id', element: <QuestionUpdate /> },
            { path: ':id', element: <QuestionDetail /> },
            { path: 'create', element: <QuestionUpdate /> },
          ],
        },
        {
          path: 'role',
          children: [
            { element: <Navigate to={PATH_DASHBOARD.role.root} replace />, index: true },
            { path: 'management', element: <RoleManagement /> },
            { path: 'create', element: <RoleCreate /> },
            { path: 'update/:id', element: <RoleUpdate /> },
          ],
        },
        {
          path: 'noti',
          children: [
            { element: <Navigate to={PATH_DASHBOARD.noti.root} replace />, index: true },
            { path: 'history', element: <NotiHistory /> },
            { path: 'management', element: <NotiManagement /> },
            { path: 'create', element: <NotiDetail /> },
            { path: 'update/:id', element: <NotiDetail /> },
          ],
        },
        {
          path: 'feedback',
          children: [
            { element: <Navigate to={PATH_DASHBOARD.feedback.root} replace />, index: true },
            { path: 'management', element: <SurveyManagement /> },
            { path: 'create', element: <FeedbackDetail /> },
            { path: 'update/:id', element: <FeedbackDetail /> },
          ],
        },
        {
          path: 'meta-data',
          children: [
            { element: <Navigate to={PATH_DASHBOARD.metaData.root} replace />, index: true },
            { path: 'management', element: <MetaDataManagement /> },
            { path: 'management/:type', element: <MetaDataManagement /> },
            { path: 'create', element: <MetaDataDetail /> },
            { path: 'update/:id', element: <MetaDataDetail /> },
          ],
        },
        {
          path: 'report-user',
          children: [
            { element: <Navigate to={PATH_DASHBOARD.reportUser.root} replace />, index: true },
            { path: 'management', element: <ReportUserManagement /> },
            { path: ':id', element: <DetailUser /> },
          ],
        },
        {
          path: 'black-list',
          children: [
            { element: <Navigate to={PATH_DASHBOARD.blackList.root} replace />, index: true },
            { path: 'management', element: <BlackListManagement /> },
            { path: ':id', element: <DetailUser /> },
          ],
        },
        {
          path: 'referral',
          children: [
            { element: <Navigate to={PATH_DASHBOARD.referral.root} replace />, index: true },
            { path: 'event', element: <LinkEvent /> },
            { path: 'management', element: <LinkManagement /> },
            { path: 'revenue', element: <LinkRevenue /> },
            { path: 'contract', element: <Contract /> },
            { path: 'contract/create', element: <DetailContract /> },
            { path: 'contract/:id', element: <DetailContract /> },
            { path: 'entitlement', element: <Entitlement /> },
            { path: 'entitlement/:id', element: <DetailEntitlement /> },
            { path: 'entitlement/create', element: <DetailEntitlement /> },
            { path: 'entitlement/detail/:id', element: <TypeDetailEntitlement /> },
            { path: ':id', element: <DetailLink /> },
          ],
        },
        // {
        //   path: 'report',
        //   children: [
        //     { element: <Navigate to={PATH_DASHBOARD.report.root} replace />, index: true },
        //     { path: 'management', element: <GeneralApp /> },
        //   ],
        // },
        {
          path: 'blog',
          children: [
            { element: <Navigate to="/dashboard/blog/posts" replace />, index: true },
            { path: 'posts', element: <BlogPosts /> },
            { path: 'post/:title', element: <BlogPost /> },
            { path: 'new-post', element: <BlogNewPost /> },
          ],
        },
        {
          path: 'mail',
          children: [
            { element: <Navigate to="/dashboard/mail/all" replace />, index: true },
            { path: 'label/:customLabel', element: <Mail /> },
            { path: 'label/:customLabel/:mailId', element: <Mail /> },
            { path: ':systemLabel', element: <Mail /> },
            { path: ':systemLabel/:mailId', element: <Mail /> },
          ],
        },
        {
          path: 'chat',
          children: [
            { element: <Chat />, index: true },
            { path: 'new', element: <Chat /> },
            { path: ':conversationKey', element: <Chat /> },
          ],
        },
        { path: 'calendar', element: <Calendar /> },
        { path: 'kanban', element: <Kanban /> },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        // { path: 'coming-soon', element: <ComingSoon /> },
        // { path: 'maintenance', element: <Maintenance /> },
        // { path: 'pricing', element: <Pricing /> },
        // { path: 'payment', element: <Payment /> },
        // { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    // {
    //   path: '/',
    //   element: <MainLayout />,
    //   children: [
    //     { element: <HomePage />, index: true },
    //     { path: 'about-us', element: <About /> },
    //     { path: 'contact-us', element: <Contact /> },
    //     { path: 'faqs', element: <Faqs /> },
    //   ],
    // },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
// const Register = Loadable(lazy(() => import('../pages/auth/Register')));
// const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
// const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));
// Dashboard
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
const GeneralEcommerce = Loadable(lazy(() => import('../pages/dashboard/GeneralEcommerce')));
const GeneralAnalytics = Loadable(lazy(() => import('../pages/dashboard/GeneralAnalytics')));
const GeneralBanking = Loadable(lazy(() => import('../pages/dashboard/GeneralBanking')));
const GeneralBooking = Loadable(lazy(() => import('../pages/dashboard/GeneralBooking')));
const EcommerceShop = Loadable(lazy(() => import('../pages/dashboard/EcommerceShop')));
const EcommerceProductDetails = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductDetails')));
const EcommerceProductList = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductList')));
const EcommerceProductCreate = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductCreate')));
const EcommerceCheckout = Loadable(lazy(() => import('../pages/dashboard/EcommerceCheckout')));
const EcommerceInvoice = Loadable(lazy(() => import('../pages/dashboard/EcommerceInvoice')));
const BlogPosts = Loadable(lazy(() => import('../pages/dashboard/BlogPosts')));
const BlogPost = Loadable(lazy(() => import('../pages/dashboard/BlogPost')));
const BlogNewPost = Loadable(lazy(() => import('../pages/dashboard/BlogNewPost')));
// const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile')));
// const UserCards = Loadable(lazy(() => import('../pages/dashboard/UserCards')));
const UserManagement = Loadable(lazy(() => import('../pages/dashboard/user/UserManagement')));
const QuestionManagement = Loadable(lazy(() => import('../pages/dashboard/question/QuestionManagement')));
const QuestionDetail = Loadable(lazy(() => import('../pages/dashboard/question/Detail')));
const QuestionUpdate = Loadable(lazy(() => import('../pages/dashboard/question/Update')));
const RoleManagement = Loadable(lazy(() => import('../pages/dashboard/role/RoleManagement')));
const RoleCreate = Loadable(lazy(() => import('../pages/dashboard/role/Create')));
const RoleUpdate = Loadable(lazy(() => import('../pages/dashboard/role/Update')));
const NotiManagement = Loadable(lazy(() => import('../pages/dashboard/noti/NotiManagement')));
const SurveyManagement = Loadable(lazy(() => import('../pages/dashboard/feedback/SurveyManagement')));
const FeedbackDetail = Loadable(lazy(() => import('../pages/dashboard/feedback/Detail')));
const NotiHistory = Loadable(lazy(() => import('../pages/dashboard/notiHistory/NotiHistoryManagement')));
const NotiDetail = Loadable(lazy(() => import('../pages/dashboard/noti/Detail')));
const MetaDataManagement = Loadable(lazy(() => import('../pages/dashboard/metadata/MetaDataManagement')));
const MetaDataDetail = Loadable(lazy(() => import('../pages/dashboard/metadata/Detail')));
const ReportUserManagement = Loadable(lazy(() => import('../pages/dashboard/blockreport/ReportUserManagement')));
const ReportManagement = Loadable(lazy(() => import('../pages/dashboard/report/ReportDashboard')));
const BlackListManagement = Loadable(lazy(() => import('../pages/dashboard/BlackList/BlackListManagement')));
const DetailUser = Loadable(lazy(() => import('../pages/dashboard/user/Detail')));
// const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
// const UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')));
const Chat = Loadable(lazy(() => import('../pages/dashboard/Chat')));
const Mail = Loadable(lazy(() => import('../pages/dashboard/Mail')));
const Calendar = Loadable(lazy(() => import('../pages/dashboard/Calendar')));
const Kanban = Loadable(lazy(() => import('../pages/dashboard/Kanban')));
// Main
// const HomePage = Loadable(lazy(() => import('../pages/Home')));
// const About = Loadable(lazy(() => import('../pages/About')));
// const Contact = Loadable(lazy(() => import('../pages/Contact')));
// const Faqs = Loadable(lazy(() => import('../pages/Faqs')));
// const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
// const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
// const Pricing = Loadable(lazy(() => import('../pages/Pricing')));
// const Payment = Loadable(lazy(() => import('../pages/Payment')));
// const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
