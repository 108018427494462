import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
// @mui
import {useTheme} from "@mui/material/styles";
import {
    Avatar, Box,
    Card,
    Container,
    Grid,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow, Typography,
} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import Loading from '../../../../components/Loading';
import {getContracts, getLinkEntitlements, getEvents, getRevenues} from '../../../../redux/slices/link';
// components
import Page from '../../../../components/Page';
import Scrollbar from '../../../../components/Scrollbar';
import SearchNotFound from '../../../../components/SearchNotFound';
// sections
import {LinkListHead, RevenueListToolbar} from '../list';
import {BankingWidgetSummary} from "../../general/banking";
import Iconify from "../../../../components/Iconify";
// ----------------------------------------------------------------------

export default function Revenues({ tableHead, dataChanged,setDataChanged, defaultContract }) {
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {revenues, total, events, contracts, entitlements, totalRevenue, revenueSevenDays, isLoading } = useSelector((state) => state.link);
    const [firstTime, setFirstTime] = useState(true);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [sort, setSort] = useState('-event_timestamp');
    const [filterEvent, setFilterEvent] = useState([]);
    const [filterContract, setFilterContract] = useState([]);
    const [filterEntitlement, setFilterEntitlement] = useState([]);
    function formatTimestamp(timestamp) {
        const date = new Date(timestamp); // Convert seconds to milliseconds
        return date.toLocaleString();
    }
    function formatCustomDate(dateString) {
        const date = new Date(dateString);
        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            timeZoneName: 'short',
        };
        return new Intl.DateTimeFormat('en-US', options).format(date);
    }

    function formatUSDCurrency(revenue) {
        return revenue.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD'
        });
    }

    function getIncreasePercent(revenueSevenDays) {
        if (revenueSevenDays.length < 2) {
            return 0;
        }
        const first = revenueSevenDays[revenueSevenDays.length - 2];
        const last = revenueSevenDays[revenueSevenDays.length - 1];
        return parseFloat(((last.total_revenue - first.total_revenue) / first.total_revenue * 100).toFixed(2));
    }
    const fetchRevenues = (page = 1, page_size = 10, sort = '-event_timestamp',filterEvent=[], filterContract =[], filterEntitlement = []) => {
        // Extract the 'id' property from each object in filterSub
        // Join the subscriptionIds array into a comma-separated string
        let eventIds = filterEvent.map(e => e.id)
        eventIds = eventIds.join(',')
        let contractIds = filterContract.map(e => e.id)
        contractIds = contractIds.join(',')
        let entitlementIds = filterEntitlement.map(e => e.id)
        entitlementIds = entitlementIds.join(',')

        const params = {
            page,
            page_size,
            sort,
            referral_event_id: eventIds,
            referral_contract_id: contractIds,
            referral_entitlement_id: entitlementIds,
        };
        dispatch(getRevenues(params));
    };

    useEffect(() => {
        if (!firstTime) {
            fetchRevenues(page, pageSize, sort,filterEvent, filterContract, filterEntitlement);
        }
    }, [page]);

    useEffect(() => {
        if (!firstTime) {
            fetchRevenues(1, pageSize, sort,filterEvent, filterContract, filterEntitlement);
        }
    }, [pageSize, sort, filterEvent, filterContract, filterEntitlement]);

    useEffect(() => {
        if (firstTime) {
            fetchRevenues();
        }
        dispatch(getEvents());
        dispatch(getLinkEntitlements());
        dispatch(getContracts());
        setFirstTime(false);
    }, []);


    useEffect(() => {
        if (dataChanged) {
            fetchRevenues();
        }
        setDataChanged(false);
    }, [dataChanged]);

    useEffect(() => {
        if (defaultContract) {
            setFilterContract([defaultContract])
            fetchRevenues(page, pageSize, sort,filterEvent, [defaultContract], filterEntitlement);
        }
    }, [defaultContract]);

    const handleChangeRowsPerPage = (event) => {
        setPageSize(parseInt(event.target.value, 10));
        setPage(1);
    };

    return (
        <Page title="Link: List">
            {isLoading && <Loading />}
            <Container>
                <Card sx={{ p: 2, my: 2 }}>
                    <Grid item xs={12} md={12} mb={5}>
                        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}>
                            <BankingWidgetSummary
                                title="Total Earned"
                                icon={'eva:diagonal-arrow-right-up-fill'}
                                percent={getIncreasePercent(revenueSevenDays)}
                                total={totalRevenue * 1 / 100}
                                chartData={revenueSevenDays.map(revenue => revenue.total_revenue*1/100)  || []}
                            />
                            <BankingWidgetSummary
                                title="Total Sent"
                                color="warning"
                                icon={'eva:diagonal-arrow-right-down-fill'}
                                percent={0}
                                total={0}
                                chartData={[0]}
                            />
                        </Stack>
                    </Grid>
                    <RevenueListToolbar
                        eventsOption={events}
                        filterEvent={filterEvent}
                        setFilterEvent={setFilterEvent}

                        entitlementOption={entitlements}
                        filterEntitlement={filterEntitlement}
                        setFilterEntitlement={setFilterEntitlement}

                        contractOption={contracts}
                        filterContract={filterContract}
                        setFilterContract={setFilterContract}
                        defaultContract={defaultContract}
                        sort={sort}
                        setSort={setSort}
                    />
                </Card>
                <Card sx={{ p: 2, my: 2 }}>
                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                <LinkListHead
                                    headLabel={tableHead}
                                    rowCount={revenues.length}
                                />
                                <TableBody>
                                    {revenues.map((row) => {
                                        const {
                                            id,
                                            created_at,
                                            total_revenue,
                                            referral_history,
                                            referral_event,
                                            referral_link,
                                            referral_contract,
                                            is_paid
                                        } = row;
                                        return (
                                            <TableRow
                                                hover
                                                key={id}
                                                tabIndex={-1}
                                                role="checkbox"
                                                style={{ verticalAlign: 'top' }}
                                            >
                                                <TableCell
                                                    onClick={() => navigate(`/referral/${referral_link?.id}`, { state: { page } })}
                                                    align="left"
                                                    sx={{ cursor: 'pointer' }}
                                                >
                                                    {referral_link?.url || ''}
                                                </TableCell>
                                                <TableCell align="left" >
                                                    {referral_event?.name || ''}
                                                </TableCell>
                                                {
                                                    referral_history?.user_id ? (
                                                        <TableCell
                                                            sx={{ display: 'flex', alignItems: 'center' }}
                                                            onClick={() => navigate(`/user/${referral_history?.user?.id}`)}
                                                        >
                                                            <Avatar alt={referral_history?.user?.first_name} src={referral_history?.user?.avatar?.url?.thumbnail || ''} sx={{ mr: 2 }} />
                                                            <Box>
                                                                <Typography
                                                                    variant="subtitle2"
                                                                    onClick={() => navigate(`/user/${referral_history?.user?.id}`)}
                                                                    noWrap
                                                                    color={theme.palette.primary.main}
                                                                    sx={{ cursor: 'pointer' }}
                                                                >
                                                                    {referral_history?.user?.first_name}
                                                                </Typography>
                                                                <Typography variant="caption" noWrap>
                                                                    {referral_history?.user?.phone_number}
                                                                </Typography>
                                                            </Box>
                                                        </TableCell>
                                                    ): (
                                                        <TableCell>
                                                            {''}
                                                        </TableCell>
                                                    )
                                                }
                                                <TableCell>
                                                    {formatCustomDate(created_at)}
                                                </TableCell>

                                                <TableCell align="left" >
                                                   {referral_contract?.phone_number || ''}
                                                </TableCell>
                                                <TableCell align="left" >
                                                    {formatUSDCurrency(total_revenue*1/100) || ''}
                                                </TableCell>
                                                <TableCell align="left" >
                                                    {
                                                        is_paid ? (
                                                            'True'
                                                        ) : (
                                                            'False'
                                                        )
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                                {!revenues.length && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                <SearchNotFound searchQuery={''} />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>

                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={total}
                        rowsPerPage={pageSize}
                        page={page - 1}
                        onPageChange={(e, page) => setPage(page + 1)}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>

            </Container>
        </Page>
    );
}
