import PropTypes from 'prop-types';
// @mui
import {
    InputAdornment,
    Grid,
} from '@mui/material';
// components
import Iconify from '../../../../components/Iconify';
import InputStyle from '../../../../components/InputStyle';



EventListToolbar.propTypes = {
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
};

export default function EventListToolbar({filterName, onFilterName}) {
  return (
    <Grid container spacing={4} style={{ display: 'flex' }}>
      <Grid item xs={12} md={3}>
        <InputStyle
          value={filterName}
          onChange={(event) => onFilterName(event.target.value)}
          placeholder="Search..."
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify icon={'eva:search-fill'} sx={{ color: 'text.disabled', width: 20, height: 20 }} />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
    </Grid>
  );
}
