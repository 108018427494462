import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: null,
    reportDetail: null,
};

const slice = createSlice({
    name: 'report',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET role detail
        getRoleDetailSuccess(state, action) {
            state.isLoading = false;
            state.reportDetail = action.payload;
        },
    },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getReport({ ...params}) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get(`/v1/admin/report`,{
                params,
            });
            dispatch(slice.actions.getRoleDetailSuccess(response.data?.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

