import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  metaDataList: [],
  total: 0,
  metaDataDetail: null,
  metadataTypes: [],
};

const slice = createSlice({
  name: 'setting',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    setMetaDataDetail(state, action) {
      state.metaDataDetail = action.payload;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET metaDataList
    getKeySettingsSuccess(state, action) {
      state.isLoading = false;
      state.metaDataList = action.payload.metaDataList;
      state.total = action.payload.total;
    },

    // GET metaDataList
    getKeySettingDetailSuccess(state, action) {
      state.isLoading = false;
      state.metaDataDetail = action.payload;
    },

    getMetadataTypesSuccess(state, action) {
      state.isLoading = false;
      state.metadataTypes = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

export const { setMetaDataDetail } = slice.actions;

// ----------------------------------------------------------------------

export function getMetaDataList({ ...params }) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/v1/metadata/get-list`, {
        params,
      });
      dispatch(
        slice.actions.getKeySettingsSuccess({
          metaDataList: response.data?.data || [],
          total: response.data?.meta?.total || 0,
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getMetaDataType() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const params = {
        type: 'metadata_type',
        page: 1,
        page_size: 1000,
      };
      const response = await axios.get(`/v1/metadata/get-list`, {
        params,
      });
      dispatch(
        slice.actions.getMetadataTypesSuccess(response.data?.data || []));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getMetaDataDetail(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/v1/metadata/get-one/${id}`);
      dispatch(slice.actions.getKeySettingDetailSuccess(response.data?.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function createMetaData(data) {
  try {
    const response = await axios.post(`/v1/metadata/create`, {
      ...data,
    });
    return response;
  } catch (error) {
    return error;
  }
}

export async function updateMetaData(data, id) {
  const response = await axios.put(`/v1/metadata/update/${id}`, {
    ...data,
  });
  return response.data;
}

export async function deleteMetaData(id) {
  try {
    const response = await axios.delete(`/v1/metadata/delete/${id}`);
    return response;
  } catch (error) {
    return error?.error;
  }
}
