import axios from 'axios';
// ----------------------------------------------------------------------


const getBaseUrl = () => {
  let url;
  switch (process.env.REACT_APP_NODE_ENV) {
    case 'local':
      url = 'http://localhost:8081'
      break;
    case 'development':
      url = 'https://gateway.noormatch.app/noormatch/api';
      break;
    default:
      url = 'https://gateway.myjoon.app/joon';
  }

  return url;
}

const axiosInstance = axios.create({
  baseURL: getBaseUrl(),
});


axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;
