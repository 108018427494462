import PropTypes from 'prop-types';
// @mui
import {
    TextField,
    InputAdornment,
    Grid,
} from '@mui/material';
// components
import Iconify from '../../../../components/Iconify';
import InputStyle from '../../../../components/InputStyle';

// ----------------------------------------------------------------------
const DATE_OPTION = [
  { id: '-created_at', label: 'Latest' },
  { id: 'created_at', label: 'Oldest' },
];

LinkListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  onDeleteUsers: PropTypes.func,
};

export default function LinkListToolbar({ sort, setSort, filterName, onFilterName}) {
  return (
    <Grid container spacing={4} style={{ display: 'flex' }}>
      <Grid item xs={12} md={3}>
        <InputStyle
          value={filterName}
          onChange={(event) => onFilterName(event.target.value)}
          placeholder="Search phone number..."
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify icon={'eva:search-fill'} sx={{ color: 'text.disabled', width: 20, height: 20 }} />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <TextField
          select
          label="Sort"
          value={sort}
          fullWidth
          onChange={(e) => setSort(e.target.value)}
          SelectProps={{ native: true }}
          variant="outlined"
        >
          {DATE_OPTION.map((option) => (
            <option key={option.id} value={option.id}>
              {option.label}
            </option>
          ))}
        </TextField>
      </Grid>
    </Grid>
  );
}
