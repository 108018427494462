import {createSlice} from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import {dispatch} from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  contracts: [],
  total: 0,
  contractDetail: null,
  entitlements: [],
};

const slice = createSlice({
  name: 'contract',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET USERS
    getContractsSuccess(state, action) {
      state.isLoading = false;
      state.contracts = action.payload.contracts;
      state.total = action.payload.total;
    },

    getEntitlementSuccess(state, action) {
      state.isLoading = false;
      state.entitlements = action.payload.entitlements;
    },

     getContractSuccess(state, action) {
      state.isLoading = false;
      state.contracts = action.payload.contracts;
      state.total = action.payload.total;
    },

    getContractsDetailSuccess(state, action) {
      state.isLoading = false;
      state.contractDetail = action.payload;
    },

  },
});

// Reducer
export default slice.reducer;
export const { getContractsDetailSuccess } = slice.actions;
// ----------------------------------------------------------------------

export function getContracts({ ...params }) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/v1/admin/referral/contract/get-list', { params });
      dispatch(
        slice.actions.getContractSuccess({
          contracts: response.data?.data || [],
          total: response.data?.meta?.total || 0,
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function getContractsDetail(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/v1/admin/referral/contract/get-one/${id}`);
      dispatch(slice.actions.getContractsDetailSuccess(response.data?.data || {}));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getContractEntitlements() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const params = {
        page: 1,
        page_size: 1000,
      };
      const response = await axios.get('/v1/admin/referral/entitlement/get-list', { params });
      dispatch(
          slice.actions.getEntitlementSuccess({
            entitlements: response.data?.data || [],
          })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export async function createContracts(data) {
  try {
    return await axios.post(`/v1/admin/referral/contract/create`, {
      ...data,
    });
  } catch (error) {
    return error;
  }
}

export async function deleteContracts(id) {
  try {
    return await axios.delete(`/v1/admin/referral/contract/delete/${id}`);
  } catch (error) {
    return error;
  }
}

export async function updateContracts(data,id) {
  try {
    return await axios.put(`/v1/admin/referral/contract/update/${id}`, {
      ...data,
    });
  } catch (error) {
    return error;
  }
}

