import {createSlice} from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import {dispatch} from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  links: [],
  revenues: [],
  events: [],
  entitlements: [],
  contracts: [],
  total: 0,
  linkDetail: null,
  totalRevenue: 0,
  revenueSevenDays: [],
};

const slice = createSlice({
  name: 'link',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET USERS
    getLinksSuccess(state, action) {
      state.isLoading = false;
      state.links = action.payload.links;
      state.total = action.payload.total;
    },

    getRevenuesSuccess(state, action) {
      state.isLoading = false;
      state.revenues = action.payload.revenues;
      state.total = action.payload.total;
      state.totalRevenue = action.payload.totalRevenue;
      state.revenueSevenDays = action.payload.revenueSevenDays;
    },

    getEventSuccess(state, action) {
      state.isLoading = false;
      state.events = action.payload.events;
    },

    getEntitlementSuccess(state, action) {
      state.isLoading = false;
      state.entitlements = action.payload.entitlements;
    },

     getContractSuccess(state, action) {
      state.isLoading = false;
      state.contracts = action.payload.contracts;
    },


    // GET DETAIL USER
    getLinkDetailSuccess(state, action) {
      state.isLoading = false;
      state.linkDetail = action.payload;
    },

  },
});

// Reducer
export default slice.reducer;
export const { getLinkDetailSuccess } = slice.actions;
// ----------------------------------------------------------------------

export function getLinks({ ...params }) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/v1/admin/referral/get-list', { params });
      dispatch(
        slice.actions.getLinksSuccess({
          links: response.data?.data || [],
          total: response.data?.meta?.total || 0,
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getRevenues({ ...params }) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/v1/admin/referral/revenue/get-list', { params });
      dispatch(
        slice.actions.getRevenuesSuccess({
          revenues: response.data?.data || [],
          total: response.data?.meta?.total || 0,
          totalRevenue: response.data?.meta?.metadata?.total_revenue || 0,
          revenueSevenDays: response.data?.meta?.metadata?.revenue_seven_days || [],
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getEvents() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const params = {
        page: 1,
        page_size: 1000,
      };
      const response = await axios.get('/v1/admin/referral/event/get-list', { params });
      dispatch(
        slice.actions.getEventSuccess({
          events: response.data?.data || [],
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getLinkEntitlements() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const params = {
        page: 1,
        page_size: 1000,
      };
      const response = await axios.get('/v1/admin/referral/entitlement/get-list', { params });
      dispatch(
        slice.actions.getEntitlementSuccess({
          entitlements: response.data?.data || [],
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getContracts() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const params = {
        page: 1,
        page_size: 1000,
      };
      const response = await axios.get('/v1/admin/referral/contract/get-list', { params });
      dispatch(
        slice.actions.getContractSuccess({
          contracts: response.data?.data || [],
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function getLinkDetail(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/v1/admin/referral/get-one/${id}`);
      dispatch(slice.actions.getLinkDetailSuccess(response.data?.data || {}));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function createLink(data) {
  try {
    return await axios.post(`/v1/admin/referral/create`, {
      ...data,
    });
  } catch (error) {
    return error;
  }
}

export async function deleteLink(id) {
  try {
    return await axios.delete(`/v1/admin/referral/soft-delete/${id}`);
  } catch (error) {
    return error;
  }
}

export async function deleteHardLink(id) {
  try {
    return await axios.delete(`/v1/admin/referral/hard-delete/${id}`);
  } catch (error) {
    return error;
  }
}

export async function updateLink(data,id) {
  try {
    return await axios.put(`/v1/admin/referral/update/${id}`, {
      ...data,
    });
  } catch (error) {
    return error;
  }
}

