/* eslint-disable array-callback-return */
/* eslint-disable no-plusplus */
/* eslint-disable no-bitwise */
import * as Yup from 'yup';
import React, {useEffect, useState} from 'react';
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {useSnackbar} from "notistack";
import { useNavigate, useParams } from 'react-router-dom';
import * as PropTypes from "prop-types";
// @mui
import { useTheme, styled } from '@mui/material/styles';
import {
  Card,
  Button,
  Container,
  Typography,
  DialogContentText,
  Grid,
  Stack,
  Autocomplete,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  Table,
  TableHead,
  Paper,
  Tooltip,
  IconButton,
  Dialog, DialogTitle, DialogActions, DialogContent, TextField,
} from '@mui/material';
import {DateTimePicker, LoadingButton} from '@mui/lab';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../../components/Loading';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import Page from '../../../components/Page';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import {
  createEntitlements, createEntitlementsDetail, deleteEntitlements, deleteEntitlementsDetail,
  getEntitlements,
  getEntitlementsDetail,
  getEntitlementsDetailSuccess, getEvents,
  updateEntitlements
} from "../../../redux/slices/entitlement";
import {createContracts} from "../../../redux/slices/contract";
import Iconify from "../../../components/Iconify";
import {ERROR_MESSAGE} from "../../../config";
import {createLink} from "../../../redux/slices/link";


// ----------------------------------------------------------------------

const DialogContentTextStyle = styled(DialogContentText)(({ theme }) => ({
  fontWeight: 'bold',
}));

function MuiPickersUtilsProvider(props) {
  return null;
}

MuiPickersUtilsProvider.propTypes = {
  utils: PropTypes.any,
  children: PropTypes.func
};
export default function DetailEntitlement() {
  const [dataChanged, setDataChanged] = useState(false);
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { entitlementDetail, events, isLoading } = useSelector((state) => state.entitlement);
  const { enqueueSnackbar } = useSnackbar();
  const [selectedDeleteEntitlementDetail, setSelectedDeleteEntitlementDetail] = useState(null);
  const [selectedCreateTypeDetail, setSelectedCreateTypeDetail] = useState('');
  const [limit, setLimit] = useState(0);
  const [price, setPrice] = useState(0);
  const [event, setEvent] = useState({});

  const KeySettingSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    key: Yup.date().required('Key is required'),
  });

  useEffect(() => {
    if (id) {
      dispatch(getEntitlementsDetail(id));
      dispatch(getEvents())
    }
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(getEntitlementsDetailSuccess(null));
    if (id) {
      dispatch(getEntitlementsDetail(id));
      dispatch(getEvents())
    }
  }, []);

  useEffect(() => {
    reset(defaultValues);
  }, [entitlementDetail]);


  const defaultValues = {
    name: entitlementDetail?.name || '',
    key: entitlementDetail?.key || '',
    referral_entitlement : entitlementDetail,
  };

  const methods = useForm({
    resolver: yupResolver(KeySettingSchema),
    defaultValues,
  });

  const {
    reset,
    getValues,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const referralEntitlementDetailColumns = [
    { id: 'event', label: 'Event', minWidth: 100 },
    { id: 'price', label: 'Price', minWidth: 50 },
    { id: 'currency', label: 'Currency', minWidth: 50 },
    { id: 'limit', label: 'Limit', minWidth: 50 },
    { id: 'action', label: 'Action', minWidth: 50},
  ];


  const onSubmit = async (data) => {
    const newData = { ...data, referral_entitlement_id: data.referral_entitlement?.id || null };
    const response =  id ? await updateEntitlements(newData, id) : await  createEntitlements(newData)
    if (response?.status === 200 || response?.status === 201) {
      dispatch(getEntitlementsDetail(id));
      enqueueSnackbar(id ? 'Update entitlement success' : 'Create entitlement success');
      if (id) {
        navigate(-1);
      } else {
        navigate(-2);
      }
      reset();
    }
    else {
      enqueueSnackbar(response?.error?.detail ||  'Internal server error', { variant: 'error' });
    }
  };

  const handleCloseDeleteEntitlementDetail = () => {
    setSelectedDeleteEntitlementDetail(null);
  };

  const handleConfirmDeleteEntitlementDetail = async () => {
    const response = await deleteEntitlementsDetail(selectedDeleteEntitlementDetail.id);
    if (response.status === 200) {
      dispatch(getEntitlementsDetail(id));
      enqueueSnackbar('Referral detail deleted');
      handleCloseDeleteEntitlementDetail();
    } else {
      enqueueSnackbar(response?.error?.detail || ERROR_MESSAGE, { variant: 'error' });
    }
  };

  const handleConfirmCreateTypeDetail = async () => {
    if (!price) return enqueueSnackbar('Price is required.', { variant: 'warning' });
    if (!event) return enqueueSnackbar('Event is required.', { variant: 'warning' })
    if (!id) return enqueueSnackbar('Need to create detail name first')
    console.log(event)
    const data = {
      // convert alias to upper case and trim space
      referral_entitlement_id: id,
      referral_event_id: event.id,
      price,
      limit,
      currency : "USD",
      currency_decimal: 2,
    };

    const response = await createEntitlementsDetail(data);
    if (response.status === 201) {
      dispatch(getEntitlementsDetail(id));
      enqueueSnackbar('Create type detail success');
      handleCloseCreateTypeDetail();
      setDataChanged(true)
    } else {
      enqueueSnackbar(response?.error?.detail ||  'Internal server error', { variant: 'error' });
    }
  };

  const getOptionLabel = (option) => {
    if (!option.name) return '';
    return option.name
  };

  const handleCloseCreateTypeDetail = () => {
    setSelectedCreateTypeDetail('');
    setLimit(0);
    setPrice(0)
  };

  return (
      <Page title="Entitlement Detail">
        {isLoading && <Loading />}
        <Container>
          <HeaderBreadcrumbs
              heading={entitlementDetail?.name || ''}
              links={[
                { name: 'Affiliate Types', href: PATH_DASHBOARD.referral.entitlements },
                { name:  entitlementDetail?.name || ''},
              ]}
              action={
                <Stack spacing={2} mt={4} width="100%" direction="row" justifyContent="flex-end">
                  <Button color="inherit" variant="outlined" size="large" onClick={ id ? () => navigate(-1) : () => navigate(-2) }>
                    Back
                  </Button>
                  <LoadingButton variant="contained" loading={isSubmitting} onClick={() => onSubmit(getValues())}>
                    {id ? 'Update' : 'Create'}
                  </LoadingButton>
                </Stack>
              }
          />
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Card mt={2} sx={{ p: 2, mb: 2  }}>
                <Stack spacing={1}>
                  <Typography variant="h4">Information</Typography>
                  <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2} sx={{ marginTop: 0 }}>
                      <Grid item xs={4}>
                        <DialogContentTextStyle>Name: </DialogContentTextStyle>
                      </Grid>
                      <Grid item xs={6}>
                        <RHFTextField fullWidth name="name" label="Name" />
                      </Grid>
                      <Grid item xs={4}>
                        <DialogContentTextStyle>Key: </DialogContentTextStyle>
                      </Grid>
                      <Grid item xs={6}>
                        <RHFTextField fullWidth name="key" label="key" />
                      </Grid>
                    </Grid>
                  </FormProvider>
                </Stack>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>

              <Card mt={2} sx={{ p: 2, mb: 2  }}>
                  <Grid container spacing={2} mb={3}>
                    <Grid item xs={8}>
                      <DialogContentTextStyle>Referral Type Detail: {getValues('referral_entitlement')?.name || ''}</DialogContentTextStyle>
                    </Grid>
                    <Grid item xs={4}>
                      <Button
                          color="info"
                          variant="contained"
                          startIcon={<Iconify icon="eva:plus-fill" />}
                          onClick={(e) => {
                            e.stopPropagation();
                            setSelectedCreateTypeDetail('open')
                          }}
                      >
                        Add Type Detail
                      </Button>
                    </Grid>
                  </Grid>
                {/* Table for Referral Type Detail */}
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {referralEntitlementDetailColumns.map((column) => (
                            <TableCell key={column.id}>{column.label}</TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {getValues('referral_entitlement')?.referral_entitlement_detail.map((detail) => (
                          <TableRow key={detail.id}
                                    hover
                                    tabIndex={-1}
                                    role="checkbox"
                                    onClick={() => navigate(`/referral/entitlement/detail/${detail.id}`)}
                          >
                            <TableCell>{detail.referral_event?.name}</TableCell>
                            <TableCell>{detail.price*1/100}</TableCell>
                            <TableCell>{detail.currency}</TableCell>
                            <TableCell>{detail.limit}</TableCell>
                            <TableCell align="left">
                              <Tooltip title="Delete">
                                <IconButton
                                    size="medium"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setSelectedDeleteEntitlementDetail(detail);
                                    }}
                                >
                                  <Iconify icon={'eva:trash-2-outline'} />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Card>
            </Grid>
            </Grid>
        </Container>
        {selectedDeleteEntitlementDetail && (
            <Dialog open={Boolean(selectedDeleteEntitlementDetail)} fullWidth maxWidth="xs" onClose={handleCloseDeleteEntitlementDetail}>
              <DialogTitle >Delete Entitlement Detail: {selectedDeleteEntitlementDetail.referral_event?.name}</DialogTitle>
              <DialogActions>
                <Button variant="contained" color="error" onClick={handleCloseDeleteEntitlementDetail}>
                  Cancel
                </Button>
                <Button variant="contained" onClick={handleConfirmDeleteEntitlementDetail}>
                  Confirm
                </Button>
              </DialogActions>
            </Dialog>
        )}
        {selectedCreateTypeDetail === 'open' && (
            <Dialog open={selectedCreateTypeDetail === 'open'} fullWidth maxWidth="xs" onClose={handleCloseCreateTypeDetail}>
              <DialogTitle >Create affiliate type detail</DialogTitle>
              <DialogContent sx={{ mt: 3 }}>
                <Autocomplete
                    fullWidth
                    options={events}
                    getOptionLabel={getOptionLabel}
                    renderInput={(params) => <TextField {...params} label="Event" />}
                    value={event}
                    onChange={(_, value) => {setEvent(value)}}
                />
                <TextField sx={{mt: 1, mb:1}} fullWidth placeholder="Limit" onChange={(e) => setLimit(parseInt(e.target.value,10))} />
                <TextField sx={{mt: 1}} fullWidth placeholder="Price ($ with defaul decimals is 2)" onChange={(e) => setPrice(parseInt(e.target.value,10))} />
              </DialogContent>
              <DialogActions>
                <Button variant="contained" color="error" onClick={handleCloseCreateTypeDetail}>
                  Cancel
                </Button>
                <Button variant="contained" onClick={handleConfirmCreateTypeDetail}>
                  Confirm
                </Button>
              </DialogActions>
            </Dialog>
        )}
      </Page>
  );
}
