import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import eventReducer from './slices/event';
import mailReducer from './slices/mail';
import chatReducer from './slices/chat';
import chatDetailsReducer from './slices/chat_details';
import productReducer from './slices/product';
import calendarReducer from './slices/calendar';
import kanbanReducer from './slices/kanban';
import userReducer from './slices/user';
import linkReducer from './slices/link';
import contractReducer from './slices/contract';
import entitlementReducer from './slices/entitlement';
import questionReducer from './slices/question';
import reportUserReducer from './slices/reportUser';
import report from './slices/report';
import blackListReducer from './slices/blackList';
import roleReducer from './slices/role';
import notiReducer from './slices/noti';
import settingReducer from './slices/setting';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const rootReducer = combineReducers({
  mail: mailReducer,
  chat: chatReducer,
  chatDetails: chatDetailsReducer,
  calendar: calendarReducer,
  kanban: kanbanReducer,
  product: persistReducer(productPersistConfig, productReducer),
  user: userReducer,
  link: linkReducer,
  contract: contractReducer,
  event: eventReducer,
  entitlement: entitlementReducer,
  question: questionReducer,
  reportUser: reportUserReducer,
  blackListUser: blackListReducer,
  role: roleReducer,
  noti: notiReducer,
  setting: settingReducer,
  reportDetail: report,
});

export { rootPersistConfig, rootReducer };
