import { capitalCase } from 'change-case';
import React from 'react';
import {Link as RouterLink, useNavigate} from "react-router-dom";

import {
  Container,
  Box,
  Tabs,
  Tab, Button,
} from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import Page from '../../../components/Page';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
// sections
import {Contracts} from '../../../sections/@dashboard/link/management';
import Iconify from "../../../components/Iconify";

// ----------------------------------------------------------------------
const TABLE_HEAD = [
  { id: 'contract_number', label: 'ID', alignRight: false },
  { id: 'user', label: 'User ', alignRight: false },
  { id: 'created_at', label: 'Created At', alignRight: false },
  { id: 'referral_entitlement', label: 'Type', alignRight: false },
  { id: 'referral_link', label: 'Referral Link', alignRight: false },
  { id: 'action', label: 'Action', alignRight: true },
];

export default function Contract() {
  const navigate = useNavigate();
  const PROFILE_TABS = [
    {
      value: 'Affiliates',
      component: <Contracts tableHead={TABLE_HEAD} />,
    },
  ];

  return (
      <Page title="Link: Affiliates">
        <Container>
          <HeaderBreadcrumbs
              heading="Affiliates"
              links={[{ name: 'Affiliates', href: PATH_DASHBOARD.referral.contracts }, { name: 'List' }]}
              action={
                <Button
                    variant="contained"
                    component={RouterLink}
                    to={PATH_DASHBOARD.referral.createContracts}
                    startIcon={<Iconify icon="eva:plus-fill" />}
                    onClick={() => navigate(PATH_DASHBOARD.referral.createContracts)}
                >
                  Create New
                </Button>
              }
          />
          <Tabs
              value={'Affiliates'}
              scrollButtons="auto"
              variant="scrollable"
              allowScrollButtonsMobile
              sx={{ marginLeft: '24px' }}
          >
            {PROFILE_TABS.map((tab) => (
                <Tab disableRipple key={tab.value} value={tab.value} icon={tab.icon} label={capitalCase(tab.value)} />
            ))}
          </Tabs>
          {PROFILE_TABS.map((tab) => {
            return <Box key={tab.value}>{tab.component}</Box>;
          })}
        </Container>
      </Page>
  );
}
