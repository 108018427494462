import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, isFull = false, sx }) {
  const logo = (
    <>
      {isFull ? (
        <Box sx={{ ...sx }}>
          <svg width="140" height="61" viewBox="0 0 140 61" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M23.6066 18.0171V49.1014C23.6066 52.5619 22.529 55.3384 20.3737 57.4308C18.2184 59.483 15.3718 60.509 11.8338 60.509C8.25521 60.509 5.38825 59.4628 3.23295 57.3704C1.07765 55.2378 0 52.3406 0 48.6789H5.55092C5.59158 50.7311 6.12024 52.401 7.13689 53.6886C8.19421 54.9762 9.75985 55.6201 11.8338 55.6201C13.9078 55.6201 15.4531 55.0165 16.4698 53.8093C17.4864 52.5619 17.9947 50.9926 17.9947 49.1014V18.0171H23.6066Z"
              fill="black"
            />
            <path
              d="M62.1897 20.0239C60.3963 21.4757 58.973 23.3545 56.468 22.8705C48.5259 21.3334 40.9539 25.404 37.8795 32.6629C34.9759 39.4948 37.0824 47.4084 43.0319 52.1054C48.7821 56.6315 57.0657 56.7169 62.8729 52.3331C68.9931 47.75 71.2419 40.0072 68.4522 33.033C67.9398 31.7235 67.2282 30.471 66.4596 28.8769C67.5698 28.0229 68.7084 27.1405 69.8186 26.2865C75.4265 31.3535 76.7644 44.1348 69.8471 52.5608C62.4174 61.5846 49.1521 63.1787 39.986 56.0337C30.8483 48.8887 28.8842 35.2249 36.0292 26.0018C40.5838 20.1662 48.4689 16.608 55.8702 17.6327C57.0373 17.8035 58.1759 18.0597 59.2576 18.4867C59.5423 18.6006 62.1897 20.0239 62.1897 20.0239Z"
              fill="black"
            />
            <path
              d="M60.5592 51.7923C54.1828 45.3304 54.5529 32.5776 61.1286 24.9486C68.5013 16.4087 81.3396 15.0993 90.4203 21.9597C99.2164 28.5923 101.494 41.2313 95.5443 50.5113C89.7087 59.649 77.5536 63.2357 68.3021 58.2541C69.4122 57.2578 70.3516 56.1761 71.5187 55.4929C72.2019 55.0944 73.2837 55.1513 74.1661 55.2936C83.1614 56.7454 91.3312 51.6215 93.4377 42.8539C94.9749 36.392 93.0677 30.7842 88.0292 26.4858C82.8768 22.0735 76.8704 21.2765 70.7217 24.0092C64.7153 26.6851 61.4132 31.5813 60.9578 38.2424C60.73 41.3452 61.4417 44.2772 62.9504 46.9815C63.3205 47.6362 63.6336 48.2909 64.146 49.2303C62.865 50.1128 61.6979 50.9668 60.5592 51.7923Z"
              fill="black"
            />
            <path
              d="M60.5308 3.1888C60.5874 3.07324 60.7049 3 60.8336 3H69.1683C69.297 3 69.4145 3.07324 69.4711 3.1888L71.206 6.72805C71.2752 6.86909 71.2378 7.0392 71.1158 7.13824L65.2136 11.9327C65.0897 12.0333 64.9122 12.0333 64.7883 11.9327L58.886 7.13824C58.7641 7.0392 58.7267 6.86909 58.7959 6.72805L60.5308 3.1888Z"
              stroke="black"
              strokeWidth="4.04685"
            />
            <path
              d="M139.999 60.5089H134.51L112.437 27.2586V60.5089H106.949V18.6914H112.437L134.51 51.8818V18.6914H139.999V60.5089Z"
              fill="black"
            />
          </svg>
        </Box>
      ) : (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M17.2477 28.3164C15.8799 29.117 14.7122 29.6174 14.1785 29.8509C12.1768 28.9836 1.8015 24.1797 1.8015 16.34C1.8015 12.7371 4.70391 9.83481 8.30691 9.80145C9.50791 9.80145 10.6755 10.1351 11.6764 10.7355C12.1101 11.0024 12.6438 10.869 12.9107 10.4353C13.1776 10.0016 13.0442 9.46785 12.6105 9.20097C11.3094 8.43368 9.84152 8 8.30691 8C3.70308 8.06672 0 11.8031 0 16.3734C0.0333611 26.1813 13.2777 31.4522 13.8448 31.6857C13.9449 31.7191 14.045 31.7525 14.1785 31.7525C14.2785 31.7525 14.412 31.7191 14.5121 31.6857C14.5788 31.6524 16.1134 31.0519 18.1484 29.8843C18.5821 29.6508 18.7156 29.0836 18.482 28.65C18.2151 28.2496 17.6814 28.0828 17.2477 28.3164Z"
            fill="#F74646"
          />
          <path
            d="M31.6929 8.03333C30.3918 8.03333 29.1241 8.33357 27.9898 8.90069C27.7229 9.03413 27.456 9.16757 27.2225 9.33437C26.8222 9.60126 26.6887 10.1684 26.9556 10.5687C27.2225 11.0024 27.7896 11.1025 28.19 10.8356C28.3901 10.7021 28.5903 10.6021 28.7905 10.502C29.6912 10.0349 30.6587 9.83478 31.6929 9.83478C35.2626 9.83478 38.1984 12.7371 38.1984 16.34C38.1984 24.1463 27.8564 29.0169 25.8547 29.8843C23.853 29.0169 13.4777 24.1797 13.4777 16.3734C13.4777 14.8055 14.0449 13.3376 14.979 12.2367C16.18 10.8022 17.9815 9.86814 19.9831 9.86814C21.7179 9.86814 23.3526 10.5353 24.5536 11.7363C24.6203 11.803 24.7204 11.9031 24.7871 11.9698C24.8539 12.0366 24.9206 12.1366 24.9873 12.2034C25.9548 13.371 26.4552 14.8055 26.4552 16.34C26.4552 19.3091 24.9873 22.1447 22.0849 24.8135C21.9848 24.9136 21.8514 25.0137 21.7513 25.1138C21.3843 25.4474 21.3509 26.0145 21.6846 26.3814C21.8514 26.5816 22.1183 26.6817 22.3518 26.6817C22.5519 26.6817 22.7855 26.615 22.9523 26.4482C23.0857 26.3481 23.1858 26.248 23.3192 26.1479C26.622 23.1121 28.29 19.8095 28.2567 16.34C28.2567 14.5052 27.6562 12.7371 26.5553 11.3026L26.5219 11.2693C26.4886 11.2359 26.4886 11.2359 26.4552 11.2025C26.3885 11.1025 26.2884 11.0024 26.2217 10.9023C26.2217 10.9023 26.2217 10.9023 26.2217 10.8689C26.1549 10.7689 26.0549 10.6688 25.9548 10.6021C25.7212 10.3352 25.4544 10.1017 25.1875 9.9015C23.7529 8.70053 21.9181 8.06669 20.0165 8.06669C17.4477 8.06669 15.1458 9.23429 13.6112 11.1025L13.5778 11.1358C13.5445 11.2025 13.4777 11.2359 13.4444 11.3026C13.4444 11.336 13.411 11.336 13.411 11.3693C12.3435 12.7705 11.7096 14.5052 11.7096 16.4067C11.743 26.2146 24.9873 31.4856 25.5544 31.7191C25.6545 31.7524 25.7546 31.7858 25.8881 31.7858C25.9881 31.7858 26.1216 31.7524 26.2217 31.7191C26.7888 31.4856 40.0333 26.1813 39.9999 16.3734C39.9999 11.7363 36.2635 8.03333 31.6929 8.03333Z"
            fill="#F74646"
          />
        </svg>
      )}
    </>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
