import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
// utils
import Cookies from 'universal-cookie';
import axios from '../utils/axios';
import { useWebSocket } from '../webSocketProvider';

import { isValidToken, setSession } from '../utils/jwt';
// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  genOTP: () => Promise.resolve(),
  confirmOTP: () => Promise.resolve(),
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { sendMessage } = useWebSocket();

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');
        if (accessToken) {
          const response = await VerifyToken(accessToken);
          if (response?.data?.user_info) {

            setSession(accessToken);

            sendMessage({ 'topic': 'is_online', 'token': accessToken, 'platform': 'web' });

            // fetch setting and other display info
            //
            dispatch({
              type: 'INITIALIZE',
              payload: {
                isAuthenticated: true,
                user: response?.data?.user_info,
              },
            });
          } else {
            const responseRefreshToken = await ShouldRefreshToken();
            const accessToken = responseRefreshToken?.data?.access_token;
            if (accessToken) {
              setSession(accessToken);

              sendMessage({ 'topic': 'is_online', 'token': accessToken, 'platform': 'web' });

              dispatch({
                type: 'INITIALIZE',
                payload: {
                  isAuthenticated: true,
                  user: response?.data?.user_info,
                },
              });
            }
          }
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (phoneNumber) => {
    const response = await axios.post('/v1/login/gen-otp', {
      phone_number: phoneNumber,
    });
    const { accessToken, user } = response.data;

    setSession(accessToken);
    dispatch({
      type: 'LOGIN',
      payload: {
        user,
      },
    });
  };

  const VerifyToken = async (accessToken) => {
    const response = await axios.get('/v1/verify-token', {
      headers: { Authorization: accessToken.includes('bearer') ? accessToken : `Bearer ${accessToken}` },
    });
    return response.data;
  };



  const ShouldRefreshToken = async () => {
    const cookies = new Cookies();
    const refreshToken = cookies.get('refreshToken');
    if (!refreshToken) return true;

    const response = await axios.post('/v1/auth/refresh-token', {
      refresh_token: refreshToken,
    });
    return response.data;
  };

  const genOTP = async (phoneNumber) => {
    const response = await axios.post('/v1/login/gen-otp', {
      phone_number: phoneNumber,
    });
    return response.data;
  };

  const confirmOTP = async (data) => {
    const response = await axios.post('/v2/login/confirm-otp', { ...data });
    if (response.data?.data?.token) {
      // eslint-disable-next-line camelcase
      const { token, refresh_token, user_info } = response.data.data;
      sendMessage({ 'topic': 'is_online', 'token': token, 'platform': 'web' });

      setSession(token);
      const cookies = new Cookies();
      cookies.set('refreshToken', refresh_token);
      dispatch({
        type: 'LOGIN',
        payload: {
          user: user_info,
        },
      });
    }
    return response.data;
  };

  const register = async (email, password, firstName, lastName) => {
    const response = await axios.post('/api/account/register', {
      email,
      password,
      firstName,
      lastName,
    });
    const { accessToken, user } = response.data;

    window.localStorage.setItem('accessToken', accessToken);
    dispatch({
      type: 'REGISTER',
      payload: {
        user,
      },
    });
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        genOTP,
        confirmOTP,
        login,
        logout,
        register,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
