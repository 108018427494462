import { capitalCase } from 'change-case';
import React, {useEffect, useState} from 'react';
import {useSnackbar} from "notistack";
import {useSelector} from "react-redux";

import {
  Container,
  Box,
  Tabs,
  Tab,
  Button,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  TextField, Autocomplete, Typography
} from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
import {createLink} from '../../../redux/slices/link';
import {dispatch} from "../../../redux/store";

// components
import Page from '../../../components/Page';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
// sections
import {DeletedLinks, Links} from '../../../sections/@dashboard/link/management';
import Iconify from "../../../components/Iconify";
import {RHFTextField} from "../../../components/hook-form";
import {getContracts} from "../../../redux/slices/contract";
import AnalyticsWebsiteVisits from "../../../sections/@dashboard/general/analytics/AnalyticsWebsiteVisits";

// ----------------------------------------------------------------------
const TABLE_HEAD = [
  { id: 'marketing_title', label: 'Title', alignRight: false },
  { id: 'url', label: 'URL', alignRight: false },
  { id: 'referral_code', label: 'Referral Code', alignRight: false },
  { id: 'user', label: 'User', alignRight: false },
  { id: 'created_at', label: 'Created At', alignRight: false },
  { id: 'deleted_at', label: 'Deleted At', alignRight: false },
  { id: 'type', label: 'Type', alignRight: false },
  { id: 'referral_history', label: 'Referral History', alignRight: false },
  { id: 'action', label: 'Action', alignRight: true },
];

export default function LinkManagement() {
  const [selectedCreateReferral, setSelectedCreateReferral] = useState('');
  const [dataChanged, setDataChanged] = useState(false);
  const [alias, setAlias] = useState('');
  const [title, setTitle] = useState('');
  const [code, setCode] = useState('');
  const [contract, setContract] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  const [currentTab, setCurrentTab] = useState('links');
  const {contracts, total, isLoading } = useSelector((state) => state.contract);
  const [firstTime, setFirstTime] = useState(true);


  const fetchContracts = () => {
    // Extract the 'id' property from each object in filterSub
    // Join the subscriptionIds array into a comma-separated string
    const params = {
      page: 1,
      page_size: 1000,
      sort: '-created_at',
    };
    dispatch(getContracts(params));
  };
  useEffect(() => {
    if (firstTime) {
      fetchContracts();
    }
    setFirstTime(false);
  }, []);

  const handleConfirmCreateReferral = async () => {
    if (!code) return enqueueSnackbar('Referral code is required. This code is in phone number format.', { variant: 'warning' });
    if (!title) return enqueueSnackbar('Title is required. Make the link title easy to remember for you and your team.', { variant: 'warning' });
    if (!alias)
      enqueueSnackbar('Empty alias will create a random link', { variant: 'warning' });
    if (!contract) {
      if (code !== contract.phone_number) {
        enqueueSnackbar('Contract and referral code do not match', { variant: 'warning' });
        return;
      }
    }
    const data = {
      // convert alias to upper case and trim space
      referral_code: code,
      custom_alias: alias.trim().replace(' ', '-'),
      marketing_title: title,
      referral_contract_id: contract.id
    };

    const response = await createLink(data);
    if (response.status === 201) {
      enqueueSnackbar('Create link success');
      handleCloseCreateReferral();
      setDataChanged(true)
    } else {
      enqueueSnackbar(response?.error?.detail ||  'Internal server error', { variant: 'error' });
    }
  };

  const getOptionLabel = (option) => {
    if (!option.contract_number) return '';
    return `Contract No: ${option.contract_number}; Referral Code: ${option.phone_number}`
  };

  const handleCloseCreateReferral = () => {
    setSelectedCreateReferral('');
    setAlias('');
    setTitle('')
    setCode('')
  };

  const handleChangeTab = (e, value) => {
    setCurrentTab(value);
  };

  const PROFILE_TABS = [
    {
      value: 'links',
      component: <Links tableHead={TABLE_HEAD} dataChanged={dataChanged}  setDataChanged={setDataChanged} />,
    },
    {
      value: 'deleted_links',
      component: <DeletedLinks tableHead={TABLE_HEAD} dataChanged={dataChanged}  setDataChanged={setDataChanged} />,
    },
  ];

  console.log('contracts', contract)
  console.log('code', code)
  return (
      <Page title="Link: Management">
        <Container>
          <HeaderBreadcrumbs
              heading="Links Management"
              links={[{ name: 'Links', href: PATH_DASHBOARD.referral.management }, { name: 'List' }]}
              action={
                <Button
                    variant="contained"
                    startIcon={<Iconify icon="eva:plus-fill" />}
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedCreateReferral('open')
                    }}
                >
                  Create New
                </Button>
              }
          />


          <Tabs
              value={currentTab}
              scrollButtons="auto"
              variant="scrollable"
              allowScrollButtonsMobile
              sx={{ marginLeft: '24px' }}
              onChange={handleChangeTab}
          >
            {PROFILE_TABS.map((tab) => (
                <Tab disableRipple key={tab.value} value={tab.value} icon={tab.icon} label={capitalCase(tab.value)} />
            ))}
          </Tabs>
          {PROFILE_TABS.map((tab) => {
            const isMatched = tab.value === currentTab;
            return isMatched && <Box key={tab.value}>{tab.component}</Box>;
          })}
        </Container>
        {selectedCreateReferral === 'open' && (
            <Dialog open={selectedCreateReferral === 'open'} fullWidth maxWidth="xs" onClose={handleCloseCreateReferral}>
              <DialogTitle >Create new referral link</DialogTitle>
              <DialogContent sx={{ mt: 3 }}>
                { contract ? <TextField disabled value={code} fullWidth placeholder="Referral Code"/> : <TextField value={code} fullWidth placeholder="Referral Code" onChange={(e) => setCode(e.target.value)} /> }
                <TextField sx={{mt: 1}} fullWidth placeholder="Title" onChange={(e) => setTitle(e.target.value)} />
                <TextField sx={{mt: 1, mb:1}} fullWidth placeholder="Alias" onChange={(e) => setAlias(e.target.value)} />
                <Autocomplete
                    fullWidth
                    options={contracts}
                    getOptionLabel={getOptionLabel}
                    renderInput={(params) => <TextField {...params} label="Contract" />}
                    value={contract}
                    onChange={(_, value) => {setContract(value); setCode(value?.phone_number)}}
                />
              </DialogContent>
              <DialogActions>
                <Button variant="contained" color="error" onClick={handleCloseCreateReferral}>
                  Cancel
                </Button>
                <Button variant="contained" onClick={handleConfirmCreateReferral}>
                  Confirm
                </Button>
              </DialogActions>
            </Dialog>
        )}
      </Page>
  );
}
