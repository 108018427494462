

export const getBaseUrl = () => {
    let url;
    switch (process.env.REACT_APP_NODE_ENV) {
        case 'local':
            url = 'http://localhost:8081'
            break;
        case 'development':
            url = 'https://gateway.noormatch.app';
            break;
        default:
            url = 'https://api.myjoon.app';
    }

    return url;
}