import {createSlice} from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import {dispatch} from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  total: 0,
  totalEvent: 0,
  entitlements: [],
  entitlementDetail: null,
  entitlementTypeDetail: null,
  events: [],
};

const slice = createSlice({
  name: 'entitlement',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET USERS
    getEntitlementsSuccess(state, action) {
      state.isLoading = false;
      state.entitlements = action.payload.entitlements;
      state.total = action.payload.total;
    },

   getEntitlementSuccess(state, action) {
      state.isLoading = false;
      state.entitlements = action.payload.entitlements;
    },

    getEventSuccess(state, action) {
      state.isLoading = false;
      state.events = action.payload.events;
      state.totalEvent = action.payload.totalEvent;
    },

    getEntitlementsDetailSuccess(state, action) {
      state.isLoading = false;
      state.entitlementDetail = action.payload;
    },

    getEntitlementTypeDetailSuccess(state, action) {
      state.isLoading = false;
      state.entitlementTypeDetail = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;
export const { getEntitlementsDetailSuccess,getEntitlementTypeDetailSuccess,  } = slice.actions;
// ----------------------------------------------------------------------

export function getEntitlements({ ...params }) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/v1/admin/referral/entitlement/get-list', { params });
      dispatch(
        slice.actions.getEntitlementSuccess({
          entitlements: response.data?.data || [],
          total: response.data?.meta?.total || 0,
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function getEntitlementsDetail(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/v1/admin/referral/entitlement/get-one/${id}`);
      dispatch(slice.actions.getEntitlementsDetailSuccess(response.data?.data || {}));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getEntitlementTypeDetail(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/v1/admin/referral/entitlement/detail/get-one/${id}`);
      dispatch(slice.actions.getEntitlementTypeDetailSuccess(response.data?.data || {}));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function createEntitlements(data) {
  try {
    return await axios.post(`/v1/admin/referral/entitlement/create`, {
      ...data,
    });
  } catch (error) {
    return error;
  }
}

export async function deleteEntitlements(id) {
  try {
    return await axios.delete(`/v1/admin/referral/entitlement/delete/${id}`);
  } catch (error) {
    return error;
  }
}

export async function updateEntitlements(data,id) {
  try {
    return await axios.put(`/v1/admin/referral/entitlement/update/${id}`, {
      ...data,
    });
  } catch (error) {
    return error;
  }
}

export async function updateEntitlementDetail(data,id) {
  try {
    return await axios.put(`/v1/admin/referral/entitlement/detail/update/${id}`, {
      ...data,
    });
  } catch (error) {
    return error;
  }
}

export async function createEntitlementsDetail(data) {
  try {
    return await axios.post(`/v1/admin/referral/entitlement/detail/create`, {
      ...data,
    });
  } catch (error) {
    return error;
  }
}

export async function deleteEntitlementsDetail(id) {
  try {
    return await axios.delete(`/v1/admin/referral/entitlement/detail/delete/${id}`);
  } catch (error) {
    return error;
  }
}

export function getEvents() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/v1/admin/referral/event/get-list');
      dispatch(
          slice.actions.getEventSuccess({
            events: response.data?.data || [],
            totalEvent: response.data?.meta?.total || 0,
          })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}