import {createSlice} from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import {dispatch} from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  blackListUsers: [],
  total: 0,
  blackListHistory: [],
  totalHistory: 0,
};

const slice = createSlice({
  name: 'blacklist',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET blackListUsers
    getBlackListUsersSuccess(state, action) {
      state.isLoading = false;
      state.blackListUsers = action.payload.blackListUsers;
      state.total = action.payload.total;
    },

    // GET DETAIL USER
    getBlackListHistorySuccess(state, action) {
      state.isLoading = false;
      state.blackListHistory = action.payload.blackListHistory;
      state.totalHistory = action.payload.total;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getBlackListUsers({ ...params }) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/v1/admin/blacklist/get-list-user', {
        params,
      });
      dispatch(
        slice.actions.getBlackListUsersSuccess({
          blackListUsers: response.data?.data || [],
          total: response.data?.meta?.total || 0,
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getBlackListUserHistory({ ...params }) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/v1/admin/blacklist/get-list`, {
        params,
      });
      dispatch(
        slice.actions.getBlackListHistorySuccess({
          blackListHistory: response.data?.data || [],
          total: response.data?.meta?.total || 0,
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function createBlackListUser(data) {
  try {
    const response = await axios.post(`/v1/admin/blacklist/create`, {
      ...data,
    });
    return response;
  } catch (error) {
    return error;
  }
}

export async function updateBlackListUser(data, id) {
  try {
    const response = await axios.put(`/v1/admin/blacklist/update/${id}`, {
      ...data,
    });
    return await response;
  } catch (error) {
    return error;
  }
}

export async function revertBlackListUser(id) {
  try {
    return await axios.delete(`/v1/admin/blacklist/delete/${id}`);
  } catch (error) {
    return error;
  }
}
