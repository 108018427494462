import * as Yup from 'yup';
import { useMemo, useState } from 'react';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { styled } from '@mui/material/styles';
import { Stack, Alert, Box, Autocomplete, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import parsePhoneNumber from 'libphonenumber-js';
// routes
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// components
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import { ERROR_MESSAGE } from '../../../config';

// ----------------------------------------------------------------------
const AutoCompleteStyle = styled(Autocomplete)(() => ({
  width: '160px',
  padding: 0,
  marginRight: '10px',
}));

export default function LoginForm({ setStep, countryCode, setCountryCode, phoneNumber, setPhoneNumber }) {
  const { genOTP } = useAuth();

  const isMountedRef = useIsMountedRef();
  const [errorCode, setErrorCode] = useState(false);
  const uniqueCountries = useMemo(
        () =>
        [
          { "name": "United States", "dial_code": "+1", "code": "US", "preferred": true, "flag": "🇺🇸" },
          { "name": "Viet Nam", "dial_code": "+84", "code": "VN", "flag": "🇻🇳" },
        ]
  );

  const LoginSchema = Yup.object().shape({
    phone_number: Yup.string().required('Phone number is required'),
  });

  const methods = useForm({ resolver: yupResolver(LoginSchema), defaultValues: { phone_number: phoneNumber } });

  const {
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const handleChangeCountryCode = (event, newCode) => {
    setCountryCode(newCode);
    if (errorCode) setErrorCode(false);
  };

  const onSubmit = async (data) => {
    try {
      if (!countryCode) return setErrorCode(true);
      const parsePhone = parsePhoneNumber(`${countryCode}${data.phone_number}`);
      if (parsePhone) {
        const response = await genOTP(parsePhone.number);
        if (response.data.status === 'created') {
          setPhoneNumber(parsePhone.number);
          setStep(2);
        } else {
          setError('afterSubmit');
        }
      }
    } catch (error) {
      console.error(error);
      if (isMountedRef.current) {
        setError('afterSubmit', error);
      }
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.error.detail || ERROR_MESSAGE}</Alert>}
        <Box display="flex">
          <AutoCompleteStyle
            select
            disableClearable
            value={countryCode}
            onChange={handleChangeCountryCode}
            options={uniqueCountries.map((option) => option.dial_code)}
            // SelectProps={{ native: true }}
            variant="outlined"
            renderInput={(params) => (
              <TextField
                {...params}
                label="Code"
                error={errorCode}
                helperText={errorCode && 'Country Code is required'}
              />
            )}
          />
          <RHFTextField name="phone_number" label="Phone number" type="number" />
        </Box>

        {/* <RHFTextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        /> */}
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        {/* <RHFCheckbox name="remember" label="Remember me" /> */}
        {/* <Link component={RouterLink} variant="subtitle2" to={PATH_AUTH.resetPassword}>
          Forgot password?
        </Link> */}
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
        Send OTP
      </LoadingButton>
    </FormProvider>
  );
}
