/* eslint-disable array-callback-return */
/* eslint-disable no-plusplus */
/* eslint-disable no-bitwise */
import * as Yup from 'yup';
import React, {useEffect, useState} from 'react';
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {useSnackbar} from "notistack";
import { useNavigate, useParams } from 'react-router-dom';
import ReactJson from 'react-json-view'
import { CopyToClipboard } from 'react-copy-to-clipboard';

// @mui
import Tooltip from '@mui/material/Tooltip';
import { useTheme, styled } from '@mui/material/styles';
import {
  Card,
  Button,
  Container,
  Typography,
  DialogContentText,
  Grid,
  Stack,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  TableContainer,
  Table,
  Paper,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Loading from '../../../components/Loading';
import {getLinkDetail, getLinkDetailSuccess, updateLink} from "../../../redux/slices/link";
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import Page from '../../../components/Page';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import Label from "../../../components/Label";
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import {LinkTree} from "../../../sections/@dashboard/link/management";

// ----------------------------------------------------------------------

const DialogContentTextStyle = styled(DialogContentText)(({ theme }) => ({
  fontWeight: 'bold',
}));

export default function DetailLink() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { linkDetail, isLoading } = useSelector((state) => state.link);
  const { enqueueSnackbar } = useSnackbar();

  const KeySettingSchema = Yup.object().shape({
    title: Yup.string().required('Title is required'),
    referral_code: Yup.string().required('Referral code is required'),
  });

  useEffect(() => {
    if (id) dispatch(getLinkDetail(id));
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(getLinkDetailSuccess(null));
    if (id) {
      dispatch(getLinkDetail(id));
    }
  }, []);

  useEffect(() => {
    reset(defaultValues);
  }, [linkDetail]);

  const defaultValues = {
    marketing_title: linkDetail?.json_value?.data?.$marketing_title || '',
    referral_code: linkDetail?.referral_code || '',
  };

  const methods = useForm({
    resolver: yupResolver(KeySettingSchema),
    defaultValues,
  });


  const {
    reset,
    getValues,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    const newData = { ...data };
    const response = await updateLink(newData, id)
    if (response?.status === 200) {
      enqueueSnackbar( 'Update link detail success');
      dispatch(getLinkDetail(id));
    }
    else {
      enqueueSnackbar(response?.error?.detail ||  'Internal server error', { variant: 'error' });
    }
  };


  return (
      <Page title="Link Detail">
        {isLoading && <Loading />}
        <Container>
          <HeaderBreadcrumbs 
              heading={
                <Tooltip title={linkDetail?.url || ''}>
                  <Typography variant="head" >
                    {linkDetail?.url.length > 50 ? `${linkDetail?.url.substring(0, 40)}...` : linkDetail?.url}
                  </Typography>
                </Tooltip>
              }
              links={[
                { name: 'Link', href: PATH_DASHBOARD.user.management },
                { name: linkDetail?.json_value?.data?.$marketing_title || ''},
              ]}
              action={
                <Stack spacing={2} mt={4} width="100%" direction="row" justifyContent="flex-end">
                  <Button color="inherit" variant="outlined" size="large" onClick={ id ? () => navigate(-1) : () => navigate(-2) }>
                    Back
                  </Button>
                  <LoadingButton variant="contained" loading={isSubmitting} onClick={() => onSubmit(getValues())}>
                    {'Update'}
                  </LoadingButton>
                </Stack>
              }
          />
          <Grid container spacing={2}>
            <Grid item xs={6} md={6}>
              <Card mt={2} sx={{ p: 2, mb: 2  }}>
                <Stack spacing={1}>
                  <Typography variant="h4">Information</Typography>
                  <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2} sx={{ marginTop: 0 }}>
                    <Grid item xs={4}>
                      <DialogContentTextStyle>URL: </DialogContentTextStyle>
                    </Grid>
                    <Grid item xs={8}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item xs={8}>
                        <Tooltip title={linkDetail?.url || ''}>
                          <Typography variant="body1" sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                            {linkDetail?.url}
                          </Typography>
                        </Tooltip>
                        <CopyToClipboard text={linkDetail?.url}>
                          <Button variant="outlined" size="small" onClick={() => enqueueSnackbar('URL copied', { variant: 'success' })}>
                            Copy
                          </Button>
                        </CopyToClipboard>
                      </Grid>
                    </Grid>
                  </Grid>
                    <Grid item xs={4}>
                      <DialogContentTextStyle>Title: </DialogContentTextStyle>
                    </Grid>
                    <Grid item xs={6}>
                        <RHFTextField fullWidth name="marketing_title" label="Title" />
                    </Grid>
                    <Grid item xs={4}>
                      <DialogContentTextStyle>Referral Code</DialogContentTextStyle>
                    </Grid>
                    <Grid item xs={8}>
                      <RHFTextField fullWidth name="referral_code" label="Referral Code" />
                    </Grid>
                    <Grid item xs={4}>
                      <DialogContentTextStyle>Link Type:</DialogContentTextStyle>
                    </Grid>
                    <Grid item xs={8}>
                      <DialogContentText>{linkDetail?.type}</DialogContentText>
                    </Grid>
                    <Grid item xs={4}>
                      <DialogContentTextStyle>Created at: </DialogContentTextStyle>
                    </Grid>
                    <Grid item xs={8}>
                      <DialogContentText>{linkDetail? moment(linkDetail.created_at).format('DD/MM/YYYY - HH:mm') : 'Unknown'}</DialogContentText>
                    </Grid>
                    <Grid item xs={4}>
                      <DialogContentTextStyle>Deleted at: </DialogContentTextStyle>
                    </Grid>
                    <Grid item xs={8}>
                      <DialogContentText>{linkDetail?.deleted_at ? moment(linkDetail.deleted_at).format('DD/MM/YYYY - HH:mm') : ' '}</DialogContentText>
                    </Grid>
                    <Grid item xs={4}>
                      <DialogContentTextStyle>Branch data: </DialogContentTextStyle>
                    </Grid>
                    <Grid item xs={8}>
                      <DialogContentText>
                        {linkDetail?.json_value ? (
                            <ReactJson
                                src={linkDetail.json_value}
                                name={false} // To hide the "name" field
                                collapsed={0} // To initially collapse the data up to the specified depth
                                theme="bright:inverted" // You can choose a theme that suits your design
                            />
                        ) : (
                            'No branch data'
                        )}
                      </DialogContentText>
                    </Grid>
                    </Grid>
                  </FormProvider>
                </Stack>
              </Card>
            </Grid>

            <Grid item xs={6} md={6}>
              <Card mt={2} sx={{ p: 2, mb: 2 }}>
                <Typography variant="h4">Referral History</Typography>
                <Grid container spacing={2} sx={{marginTop: 0, ml: 0}}>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ borderBottom: `1px solid ${theme.palette.grey[500_16]}` }}>Event</TableCell>
                          <TableCell sx={{ borderBottom: `1px solid ${theme.palette.grey[500_16]}` }}>Count</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {linkDetail?.referral_history?.map((post, index) => (
                            <TableRow key={index}>
                              <TableCell sx={{ borderBottom: `1px solid ${theme.palette.grey[500_16]}` }}>
                                <Label
                                    sx={{ mr: 1, mb: 1 }}
                                    key={post.event}
                                    variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                                    color={
                                        (post.event === 'INSTALL' && 'primary') ||
                                        (post.event === 'OPEN' && 'warning') ||
                                        (post.event === 'CLICK' && 'success') ||
                                        (post.event === 'REGISTER' && 'info') ||
                                        'error'
                                    }
                                >
                                  {post.event}
                                </Label>
                              </TableCell>
                              <TableCell sx={{ borderBottom: `1px solid ${theme.palette.grey[500_16]}`}}>
                                {post.count}
                              </TableCell>
                            </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Card>
            </Grid>

            <Grid item xs={12} md={12}>
              <Card mt={2} sx={{ p: 2, mb: 2 }}>
                <Stack spacing={1}>
                  <Typography variant="h4">Referral Tree</Typography>
                </Stack>
                <Grid item xs={12} md={12}>
                  {
                    linkDetail?.children?.length > 0 ? (
                        <LinkTree children={linkDetail?.children} />
                    ) : (
                        <Typography variant="body1">No referral tree</Typography>
                    )
                  }
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Page>
  );
}
