import React from 'react';
import moment from "moment/moment";
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { styled } from "@mui/material/styles";
import { DialogContentText } from "@mui/material";
import Label from "../../../../components/Label";

const DialogContentTextStyle = styled(DialogContentText)(({ theme }) => ({
    fontWeight: 'bold',
}));

function LinkTree({ children }) {
    return (
        <Card sx={{ ml: 10 }}>
            {children.map((child, index) => (
                <Grid container key={index}>
                    <Grid item xs={12} md={12}>
                        <Card>
                            <Grid container spacing={1} padding={1} sx={{ border: '3px solid #ccc', backgroundColor: child?.deleted_at ? '#f2f2f2' : 'inherit' }}>
                            <Grid item xs={4}>
                                    <DialogContentTextStyle>URL: </DialogContentTextStyle>
                                </Grid>
                                <Grid item xs={8}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={10}>
                                            <Typography variant="body1">
                                                {(child.url || '')}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={4}>
                                    <DialogContentTextStyle>Referral Code</DialogContentTextStyle>
                                </Grid>
                                <Grid item xs={8}>
                                    <DialogContentText>{child.referral_code}</DialogContentText>
                                </Grid>
                                <Grid item xs={4}>
                                    <DialogContentTextStyle>Deleted at</DialogContentTextStyle>
                                </Grid>
                                <Grid item xs={8}>
                                    <DialogContentText>{child?.deleted_at ? moment(child.deleted_at).format('DD/MM/YYYY - HH:mm') : ' '}</DialogContentText>
                                </Grid>
                                <Grid item xs={4}>
                                    <DialogContentTextStyle>Tracking: </DialogContentTextStyle>
                                </Grid>
                                <Grid item xs={8}>
                                    <div style={{ display: 'flex' }}>
                                        {child.referral_history.map((history, historyIndex) => (
                                            <div key={historyIndex} style={{ marginRight: '16px' }}>
                                                <Label
                                                    key={history.event}
                                                    variant="filled"
                                                    color={
                                                        (history.event === 'INSTALL' && 'primary') ||
                                                        (history.event === 'OPEN' && 'warning') ||
                                                        (history.event === 'CLICK' && 'success') ||
                                                        (history.event === 'REGISTER' && 'info') ||
                                                        'error'
                                                    }
                                                >
                                                    {history.event}: <span>{history.count}</span>
                                                </Label>
                                            </div>
                                        ))}
                                    </div>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                    {child.children && child.children.length > 0 && (
                        <LinkTree children={child.children} />
                    )}
                </Grid>
            ))}
        </Card>
    );
}

export default LinkTree;
