import {createSlice} from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import {dispatch} from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  users: [],
  total: 0,
  countTotal: [],
  userDetail: null,
  userTab: 'allUser',
  roles: [],
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET USERS
    getUsersSuccess(state, action) {
      state.isLoading = false;
      state.users = action.payload.users;
      state.total = action.payload.total;
      state.countTotal = action.payload.countTotal;
    },

    // GET DETAIL USER
    getUserDetailSuccess(state, action) {
      state.isLoading = false;
      state.userDetail = action.payload;
    },

    // SET USER TAB
    setUserTab(state, action) {
      state.userTab = action.payload;
    },

    // GET ROLES
    getRolesSuccess(state, action) {
      state.isLoading = false;
      state.roles = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Action
export const { setUserTab } = slice.actions;

// ----------------------------------------------------------------------

export function getUsers({ ...params }) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/v1/user/get-list', { params });
      dispatch(
        slice.actions.getUsersSuccess({
          users: response.data?.data || [],
          total: response.data?.meta?.total || 0,
          // convert metadata from array object {status: 'new', count: 10} to map['new'] = 10
          countTotal: convertMetadataToMap(response.data?.meta?.metadata || []),
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

function convertMetadataToMap(metadata) {
  return metadata.reduce((acc, item) => {
    acc[item.status] = item.count;
    return acc;
  }, {});
}

export function getUserDetail(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/v2/admin/user/info/${id}`);

      const userDetailData = response.data.data;
      userDetailData.user_post = userDetailData.user_post || [];
      // Check and append avatar data
      if (userDetailData?.user_profile?.avatar?.url?.origin) {
        userDetailData.user_post = [
          {
            id: userDetailData?.user_profile?.avatar.id,
            media_url: { url: { origin: userDetailData.user_profile.avatar.url.origin } },
            category: 'avatar',
            created_at: userDetailData.user_profile.avatar.created_at,
          },
          ...userDetailData.user_post,
        ];
      }
      // Check and append selfie data
      if (userDetailData?.user_profile?.selfie?.url?.file) {
        userDetailData.user_post = [
          {
            id: userDetailData?.user_profile?.selfie.id,
            media_url: { url: { file: userDetailData.user_profile.selfie.url.file } },
            category: 'selfie',
            created_at: userDetailData.user_profile.selfie.created_at,
          },
          ...userDetailData.user_post,

        ];
      }
      console.log(userDetailData)
      dispatch(slice.actions.getUserDetailSuccess(userDetailData || {}));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function updateUser(data, id) {
  try {
    const response = await axios.put(`/v1/user/update/${id}`, { ...data });
    return response.data;
  } catch (error) {
    return error;
  }
}

export async function updateRejection(data, id) {
  try {
    const response = await axios.put(`/v1/block-report/update/${id}`, { ...data });
    return response.data;
  } catch (error) {
    return error;
  }
}

export async function adminUpdateUser(data, id) {
  try {
    const response = await axios.put(`/v1/admin/user/update/${id}`, { ...data });
    return response.data;
  } catch (error) {
    return error;
  }
}

export async function deleteUser(data, id) {
  try {
    const response = await axios.delete(`/v1/admin/user/delete/${id}`, { ...data });
    return response;
  } catch (error) {
    return error;
  }
}

export async function rejectUser(data) {
  try {
    const response = await axios.post(`/v2/admin/rejection/create`, { ...data });
    return response.data;
  } catch (error) {
    return error;
  }
}

export async function rejectUserByList(data) {
  try {
    const response = await axios.post(`/v2/admin/rejection/create-by-list`, { ...data });
    return response.data;
  } catch (error) {
    return error;
  }
}

export async function updateUserRole(data) {
  try {
    const response = await axios.post(`/v1/role/set-roles`, { ...data });
    return response.data;
  } catch (error) {
    return error;
  }
}

export function getRoles() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/v1/admin/role/get-list');
      dispatch(slice.actions.getRolesSuccess(response.data?.data || []));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


