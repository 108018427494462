import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import moment from "moment";
import {useSnackbar} from "notistack";

// @mui
import {
    Button,
    Card,
    Container, Dialog, DialogActions, DialogContentText, DialogTitle, IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow, Tooltip,
} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import Loading from '../../../../components/Loading';
import {deleteEntitlements, getEntitlements} from '../../../../redux/slices/entitlement';
// components
import Page from '../../../../components/Page';
import Scrollbar from '../../../../components/Scrollbar';
import SearchNotFound from '../../../../components/SearchNotFound';
// sections
import {LinkListHead, LinkListToolbar} from '../list';
import {ERROR_MESSAGE, SEARCH_TIMEOUT} from "../../../../config";
import Iconify from "../../../../components/Iconify";
// ----------------------------------------------------------------------

export default function Entitlements({ tableHead, dataChanged,setDataChanged}) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {entitlements, total, isLoading } = useSelector((state) => state.entitlement);
    const [firstTime, setFirstTime] = useState(true);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [sort, setSort] = useState('-created_at');
    const [search, setSearch] = useState('');
    const [selectedDeleteEntitlement, setSelectedDeleteEntitlement] = useState(null);
    const { enqueueSnackbar } = useSnackbar();

    const fetchEntitlements = (page = 1, page_size = 10, sort = '-created_at',search = '') => {
        // Extract the 'id' property from each object in filterSub
        // Join the subscriptionIds array into a comma-separated string
        const params = {
            page,
            page_size,
            sort,
            search
        };
        dispatch(getEntitlements(params));
    };

    useEffect(() => {
        if (dataChanged) {
            fetchEntitlements();
        }
        setDataChanged(false);
    }, [dataChanged]);

    useEffect(() => {
        if (!firstTime) {
            fetchEntitlements(page, pageSize, sort,search);
        }
    }, [page]);

    useEffect(() => {
        if (!firstTime) {
            fetchEntitlements(1, pageSize, sort,search);
        }
    }, [pageSize, sort]);

    useEffect(() => {
        if (!firstTime) {
            setTimeout(() => {
                fetchEntitlements(1, pageSize, sort, search);
            }, SEARCH_TIMEOUT);
        }
    }, [search]);

    useEffect(() => {
        if (firstTime) {
            fetchEntitlements();
        }
        setFirstTime(false);
    }, []);

    const handleChangeRowsPerPage = (event) => {
        setPageSize(parseInt(event.target.value, 10));
        setPage(1);
    };
    const handleFilterByName = (text) => {
        setSearch(text);
        setPage(1);
    };

    const handleCloseDeleteEntitlement = () => {
        setSelectedDeleteEntitlement(null);
    };

    const handleConfirmDeleteEntitlement = async () => {
        const response = await deleteEntitlements(selectedDeleteEntitlement.id);
        if (response.status === 200) {
            fetchEntitlements();
            enqueueSnackbar('Entitlement deleted successfully', { variant: 'success' });
            handleCloseDeleteEntitlement();
        } else {
            enqueueSnackbar(response?.error?.detail || ERROR_MESSAGE, { variant: 'error' });
        }
    };

    return (
        <Page title="Link: List">
            {isLoading && <Loading />}
            <Container>
                <Card sx={{ p: 2, my: 2 }}>
                    <LinkListToolbar
                        filterName={search}
                        onFilterName={handleFilterByName}
                        sort={sort}
                        setSort={setSort}
                    />
                </Card>
                <Card sx={{ p: 2, my: 2 }}>
                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                <LinkListHead
                                    headLabel={tableHead}
                                    rowCount={entitlements.length}
                                />
                                <TableBody>
                                    {entitlements.map((row) => {
                                        const {
                                            id,
                                            name,
                                            key,
                                            created_at,
                                            referral_entitlement_detail,
                                        } = row;
                                        return (
                                            <TableRow
                                                hover
                                                key={id}
                                                tabIndex={-1}
                                                role="checkbox"
                                                onClick={() => navigate(`/referral/entitlement/${id}`, { state: { page } })}
                                            >
                                                <TableCell align="left" style={{ verticalAlign: 'top',  }}>
                                                    {name || ''}
                                                </TableCell>
                                                <TableCell align="left" style={{ verticalAlign: 'top',  }}>
                                                    {key || ''}
                                                </TableCell>
                                                <TableCell align="left" style={{ verticalAlign: 'top' }}>
                                                    <DialogContentText>{created_at ? moment(created_at).format('DD/MM/YYYY - HH:mm') : ' '}</DialogContentText>
                                                </TableCell>
                                                <TableCell align="left" style={{ verticalAlign: 'top' }}>
                                                    {referral_entitlement_detail?.length}
                                                </TableCell>
                                                <TableCell align="right">
                                                    <Tooltip title="Delete">
                                                        <IconButton
                                                            size="medium"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                setSelectedDeleteEntitlement(row);
                                                            }}
                                                        >
                                                            <Iconify icon={'eva:trash-2-outline'} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                                {!entitlements.length && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                <SearchNotFound searchQuery={search} />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>

                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={total}
                        rowsPerPage={pageSize}
                        page={page - 1}
                        onPageChange={(e, page) => setPage(page + 1)}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>

            </Container>
            {selectedDeleteEntitlement && (
                <Dialog open={Boolean(selectedDeleteEntitlement)} fullWidth maxWidth="xs" onClose={handleCloseDeleteEntitlement}>
                    <DialogTitle >Delete Entitlement: {selectedDeleteEntitlement.name}</DialogTitle>
                    <DialogActions>
                        <Button variant="contained" color="error" onClick={handleCloseDeleteEntitlement}>
                            Cancel
                        </Button>
                        <Button variant="contained" onClick={handleConfirmDeleteEntitlement}>
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </Page>
    );
}
