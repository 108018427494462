/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';
// material
import { Container, Grid } from '@mui/material';
import Page from '../../../components/Page';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import { PATH_DASHBOARD } from '../../../routes/paths';
import { AppFeatured, AppWelcome } from '../../../sections/@dashboard/general/app';

const ReportDashboard = () => (
  <Page title="Report">
    <Container style={{ maxWidth: '100%' }}>
      <HeaderBreadcrumbs
        heading="Dashboard"
        links={[{ name: 'Dashboard', href: PATH_DASHBOARD.general.dashboard }, { name: 'Statistics' }]}
      />
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <AppWelcome displayName="Jooner" />
        </Grid>

        <Grid  item xs={12} md={12} >
          <iframe
            frameBorder={0}
            width="90%"
            height="1000px"
            src="https://lookerstudio.google.com/embed/reporting/315d8270-e355-4ebd-a05a-4671950de8f1/page/5OE4D"
            allowFullScreen
            sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
          />
        </Grid>
      </Grid>

    </Container>
  </Page>
);

export default ReportDashboard;
