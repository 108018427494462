import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import { useSnackbar } from 'notistack';

import moment from "moment";

// @mui
import {
    DialogContentText,
    Card,
    Table,
    TableRow,
    TableBody,
    TableCell,
    Container,
    TableContainer,
    TablePagination,
    Avatar,
    Box,
    Typography,
} from '@mui/material';
import {useTheme} from "@mui/material/styles";
import {useDispatch, useSelector} from 'react-redux';
import Loading from '../../../../components/Loading';
// components
import Page from '../../../../components/Page';
import Scrollbar from '../../../../components/Scrollbar';
import SearchNotFound from '../../../../components/SearchNotFound';
// sections
import { EventListToolbar, LinkListHead } from '../list';
import {getEventHistory, getEventHistoryChartData} from "../../../../redux/slices/event";
import AnalyticsEvent from "../../feedback/management/AnalyticsEvent";
// ----------------------------------------------------------------------

export default function Events({ tableHead}) {
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {events, dates, data, total, isLoading } = useSelector((state) => state.event);
    const beginDate = new Date()
    const [firstTime, setFirstTime] = useState(true);
    // created_at filter
    const [date, setDate] = useState('')
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [sort, setSort] = useState('-created_at');
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('created_at');
    const [search, setSearch] = useState('');
    const [collapsedSeries, setCollapsedSeries] = useState([]);



    const fetchEvents = (search = '',date = '', page = 1, page_size = 10, sort = '-created_at',collapsedSeries=[]) => {
        if (order === 'asc') {
            sort = orderBy
        }
        if (order === 'desc') {
            sort = `-${orderBy}`
        }

        dispatch(getEventHistoryChartData({
            from: new Date(beginDate.setDate(beginDate.getDate() - 29)).toISOString(),
            to: new Date(),
        }));

        // join collapsedSeries to string separated by comma
        const filterOutEvents = collapsedSeries.join(',');

        if (date !== '') {
            const from = `${date}T00:00:00.000Z`;
            const to = `${date}T23:59:00.000Z`;
            dispatch(getEventHistory({
                page,
                page_size,
                sort,
                from,
                to,
                search,
                filter_out_event: filterOutEvents
            }));
        } else {
            console.log(date)
            dispatch(getEventHistory({
                page,
                page_size,
                sort,
                search,
                filter_out_event: filterOutEvents
            }));
        }
    }

    useEffect(() => {
        if (!firstTime) {
            fetchEvents(search, date, page, pageSize, sort, collapsedSeries);
        }
    }, [page, pageSize, sort, collapsedSeries, date, search, order, orderBy]);

    useEffect(() => {
        if (firstTime) {
            fetchEvents();
        }
        setFirstTime(false);
    }, []);


    const handleChangeRowsPerPage = (event) => {
        setPageSize(parseInt(event.target.value, 10));
        setPage(1);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleFilterByName = (text) => {
        setSearch(text);
        setPage(1);
    };

    return (
        <Page title="Link: List">
            {isLoading && <Loading />}
            <Container>

                <AnalyticsEvent
                  events={events}
                  dates={dates}
                  setDate={setDate}
                  setCollapsedSeries={setCollapsedSeries}
                />

                <Card sx={{ p: 2, my: 2 }}>
                    <EventListToolbar
                      filterName={search}
                      onFilterName={handleFilterByName}
                    />
                </Card>

                <Card sx={{ p: 2, my: 2 }}>
                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800, maxHeight: 600 }}>
                            <Table>
                                <LinkListHead
                                    headLabel={tableHead}
                                    rowCount={data.length}
                                    onRequestSort={handleRequestSort}
                                    order={order}
                                    orderBy={orderBy}
                                />

                                <TableBody>
                                    {data.map((row) => {
                                        const {
                                            id,
                                            referral_link,
                                            event,
                                            created_at,
                                            user,
                                        } = row;
                                        return (
                                            <TableRow
                                                hover
                                                key={id}
                                                tabIndex={-1}
                                            >
                                                <TableCell align="left" style={{ verticalAlign: 'top',  }}>
                                                    {referral_link?.url || ''}
                                                </TableCell>
                                                <TableCell align="left" style={{ verticalAlign: 'top',  }}>
                                                    {event || ''}
                                                </TableCell>
                                                {
                                                    user?.phone_number !== '' ? (
                                                        <TableCell
                                                            sx={{ display: 'flex', alignItems: 'center' }}
                                                            onClick={() => navigate(`/user/${user?.id}`)}
                                                        >
                                                            <Avatar alt={user?.first_name} src={user?.avatar?.url?.thumbnail || ''} sx={{ mr: 2 }} />
                                                            <Box>
                                                                <Typography
                                                                    variant="subtitle2"
                                                                    onClick={() => navigate(`/user/${user?.id}`)}
                                                                    noWrap
                                                                    color={theme.palette.primary.main}
                                                                    sx={{ cursor: 'pointer' }}
                                                                >
                                                                    {user?.first_name}
                                                                </Typography>
                                                                <Typography variant="caption" noWrap>
                                                                    {user?.phone_number}
                                                                </Typography>
                                                            </Box>
                                                        </TableCell>
                                                    ): (
                                                        <TableCell>
                                                            {'Unknown User'}
                                                        </TableCell>
                                                    )
                                                }
                                                <TableCell align="left" style={{ verticalAlign: 'top' }}>
                                                    <DialogContentText>{created_at}</DialogContentText>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                                {!data.length && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                <SearchNotFound searchQuery={''} />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>

                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={total}
                            rowsPerPage={pageSize}
                            page={page - 1}
                            onPageChange={(e, page) => setPage(page + 1)}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Scrollbar>

                </Card>

            </Container>
        </Page>
    );
}
