import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import { useSnackbar } from 'notistack';

import moment from "moment";

// @mui
import {
    DialogContentText,
    Card,
    Table,
    TableRow,
    TableBody,
    TableCell,
    Container,
    TableContainer,
    TablePagination,
    Tooltip,
    IconButton,
    Dialog,
    DialogTitle,
    DialogActions,
    Button,
    Avatar,
    Box,
    Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import {useDispatch, useSelector} from 'react-redux';
import Loading from '../../../../components/Loading';
import {getContracts, deleteContracts} from '../../../../redux/slices/contract';
// components
import Page from '../../../../components/Page';
import Scrollbar from '../../../../components/Scrollbar';
import SearchNotFound from '../../../../components/SearchNotFound';
// sections
import {LinkListHead, LinkListToolbar} from '../list';
import {ERROR_MESSAGE, SEARCH_TIMEOUT} from "../../../../config";
import Iconify from "../../../../components/Iconify";
// ----------------------------------------------------------------------

export default function Contracts({ tableHead}) {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {contracts, total, isLoading } = useSelector((state) => state.contract);
    const [firstTime, setFirstTime] = useState(true);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [sort, setSort] = useState('-created_at');
    const [search, setSearch] = useState('');
    const [selectedDeleteContract, setSelectedDeleteContract] = useState(null);
    const theme = useTheme();

    function formatUSDCurrency(revenue) {
        return revenue.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD'
        });
    }

    const fetchContracts = (page = 1, page_size = 10, sort = '-created_at',search = '') => {
        // Extract the 'id' property from each object in filterSub
        // Join the subscriptionIds array into a comma-separated string
        const params = {
            page,
            page_size,
            sort,
            search
        };
        dispatch(getContracts(params));
    };

    useEffect(() => {
        if (!firstTime) {
            fetchContracts(page, pageSize, sort,search);
        }
    }, [page]);

    useEffect(() => {
        if (!firstTime) {
            fetchContracts(1, pageSize, sort,search);
        }
    }, [pageSize, sort]);

    useEffect(() => {
        if (!firstTime) {
            setTimeout(() => {
                fetchContracts(1, pageSize, sort, search);
            }, SEARCH_TIMEOUT);
        }
    }, [search]);

    useEffect(() => {
        if (firstTime) {
            fetchContracts();
        }
        setFirstTime(false);
    }, []);

    const handleChangeRowsPerPage = (event) => {
        setPageSize(parseInt(event.target.value, 10));
        setPage(1);
    };
    const handleFilterByName = (text) => {
        setSearch(text);
        setPage(1);
    };

    const handleCloseDeleteContract = () => {
        setSelectedDeleteContract(null);
    };

    const handleConfirmDeleteContract = async () => {
        const response = await deleteContracts(selectedDeleteContract.id);
        if (response.status === 200) {
            fetchContracts();
            enqueueSnackbar('Contract Deleted', { variant: 'success' });
            handleCloseDeleteContract();
        } else {
            enqueueSnackbar(response?.error?.detail || ERROR_MESSAGE, { variant: 'error' });
        }
    };

    return (
        <Page title="Link: List">
            {isLoading && <Loading />}
            <Container>
                <Card sx={{ p: 2, my: 2 }}>
                    <LinkListToolbar
                        filterName={search}
                        onFilterName={handleFilterByName}
                        sort={sort}
                        setSort={setSort}
                    />
                </Card>
                <Card sx={{ p: 2, my: 2 }}>
                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                <LinkListHead
                                    headLabel={tableHead}
                                    rowCount={contracts.length}
                                />
                                <TableBody>
                                    {contracts.map((row) => {
                                        const {
                                            id,
                                            contract_number,
                                            phone_number,
                                            created_at,
                                            referral_entitlement,
                                            referral_link,
                                            user
                                        } = row;
                                        return (
                                            <TableRow
                                                hover
                                                key={id}
                                                tabIndex={-1}
                                                role="checkbox"
                                                onClick={() => navigate(`/referral/contract/${id}`, { state: { page } })}
                                            >
                                                <TableCell align="left" style={{ verticalAlign: 'top',  }}>
                                                    {contract_number || ''}
                                                </TableCell>
                                                <TableCell align="left" style={{ verticalAlign: 'top',  }}>
                                                    { user && (
                                                      <TableCell
                                                        sx={{ display: 'flex', alignItems: 'center' }}
                                                        onClick={() => navigate(`/user/${user?.id}`)}
                                                      >
                                                          <Avatar alt={user?.first_name} src={user?.avatar?.url?.thumbnail || ''} sx={{ mr: 2 }} />
                                                          <Box>
                                                              <Typography
                                                                variant="subtitle2"
                                                                onClick={() => navigate(`/user/${user?.id}`)}
                                                                noWrap
                                                                color={theme.palette.primary.main}
                                                                sx={{ cursor: 'pointer' }}
                                                              >
                                                                  {user?.first_name}
                                                              </Typography>
                                                              <Typography variant="caption" noWrap>
                                                                  {user?.phone_number}
                                                              </Typography>
                                                              <Typography variant="caption" noWrap>
                                                                  {user?.email}
                                                              </Typography>
                                                          </Box>
                                                      </TableCell>
                                                    )}
                                                </TableCell>
                                                <TableCell align="left" style={{ verticalAlign: 'top' }}>
                                                    <DialogContentText>{created_at ? moment(created_at).format('DD/MM/YYYY - HH:mm') : ' '}</DialogContentText>
                                                </TableCell>
                                                <TableCell align="left" style={{ verticalAlign: 'top' }}>
                                                    {referral_entitlement?.name ||''}
                                                </TableCell>
                                                <TableCell align="left" style={{ verticalAlign: 'top' }}>
                                                    {referral_link?.length}
                                                </TableCell>
                                                <TableCell align="right">
                                                    <Tooltip title="Delete">
                                                        <IconButton
                                                            size="medium"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                setSelectedDeleteContract(row);
                                                            }}
                                                        >
                                                            <Iconify icon={'eva:trash-2-outline'} />
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                                {!contracts.length && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                <SearchNotFound searchQuery={search} />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>

                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={total}
                        rowsPerPage={pageSize}
                        page={page - 1}
                        onPageChange={(e, page) => setPage(page + 1)}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>

            </Container>
            {selectedDeleteContract && (
                <Dialog open={Boolean(selectedDeleteContract)} fullWidth maxWidth="xs" onClose={handleCloseDeleteContract}>
                    <DialogTitle >Warning: Delete contract: {selectedDeleteContract.phone_number}</DialogTitle>
                    <DialogActions>
                        <Button variant="contained" color="error" onClick={handleCloseDeleteContract}>
                            Cancel
                        </Button>
                        <Button variant="contained" onClick={handleConfirmDeleteContract}>
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </Page>
    );
}
