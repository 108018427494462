/* eslint-disable array-callback-return */
/* eslint-disable no-plusplus */
/* eslint-disable no-bitwise */
import * as Yup from 'yup';
import React, {useEffect, useState} from 'react';
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {useSnackbar} from "notistack";
import { useNavigate, useParams } from 'react-router-dom';
import * as PropTypes from "prop-types";
// @mui
import { useTheme, styled } from '@mui/material/styles';
import {
  Card,
  Button,
  Container,
  Typography,
  DialogContentText,
  Grid,
  Stack,
  Autocomplete,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  Table,
  TableHead,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent, TextField, DialogActions, Tooltip, IconButton,
} from '@mui/material';
import {DateTimePicker, LoadingButton} from '@mui/lab';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../../components/Loading';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import Page from '../../../components/Page';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import {
  createContracts,
  getContractsDetail,
  getContractsDetailSuccess, getContractEntitlements,
  updateContracts
} from "../../../redux/slices/contract";
import {Revenues} from "../../../sections/@dashboard/link/management";
import {createLink, getLinks, updateLink} from "../../../redux/slices/link";
import {SEARCH_TIMEOUT} from "../../../config";
import Iconify from '../../../components/Iconify';


// ----------------------------------------------------------------------

const DialogContentTextStyle = styled(DialogContentText)(({ theme }) => ({
  fontWeight: 'bold',
}));

function MuiPickersUtilsProvider(props) {
  return null;
}

MuiPickersUtilsProvider.propTypes = {
  utils: PropTypes.any,
  children: PropTypes.func
};
export default function DetailContract() {
  const [dataChanged, setDataChanged] = useState(false);
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { contractDetail, entitlements, isLoading } = useSelector((state) => state.contract);
  const { enqueueSnackbar } = useSnackbar();
  const [selectedCreateReferral, setSelectedCreateReferral] = useState('');
  const [selectedRemoveLink, setSelectedRemoveLink] = useState(null);
  const [link, setLink] = useState({});
  const {links } = useSelector((state) => state.link);

  const KeySettingSchema = Yup.object().shape({
    user_id: Yup.string().required('User is required'),
    referral_entitlement_id: Yup.string().required('Type is required'),
  });

  useEffect(() => {
    if (selectedCreateReferral === 'open') {
      dispatch(getLinks( {
        page: 1,
        page_size: 10000,
        sort: '-created_at',
        skip_tree: true,
      }));
    }
  }, [selectedCreateReferral]);


  useEffect(() => {
    if (id) {
      dispatch(getContractsDetail(id));
    }
    dispatch(getContractEntitlements());
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(getContractsDetailSuccess(null));
    if (id) {
      dispatch(getContractsDetail(id));
    }
  }, []);

  useEffect(() => {
    reset(defaultValues);
  }, [contractDetail]);

  const defaultValues = {
    user_id: contractDetail?.user_id || '',
    referral_entitlement: contractDetail?.referral_entitlement_id
            ? entitlements?.find((entitlement) => entitlement.id === contractDetail?.referral_entitlement_id) || null
            : null,
  };

  const methods = useForm({
    resolver: yupResolver(KeySettingSchema),
    defaultValues,
  });

  const {
    reset,
    getValues,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const referralEntitlementDetailColumns = [
    { id: 'event', label: 'Event', minWidth: 100 },
    { id: 'price', label: 'Price', minWidth: 50 },
    { id: 'currency', label: 'Currency', minWidth: 50 },
    { id: 'limit', label: 'Limit', minWidth: 50 },
  ];
  const TABLE_HEAD = [
    { id: 'referral_url', label: 'Referral Link', alignRight: false },
    { id: 'referral_event', label: 'Referral Event', alignRight: false },
    { id: 'user', label: 'User' },
    { id: 'created_at', label: 'Created at', alignRight: false },
    { id: 'referral_contract', label: 'Affiliates', alignRight: false },
    { id: 'total_revenue', label: 'Revenue', alignRight: false },
    { id: 'is_paid', label: 'Is Paid', alignRight: false },
  ];
  const referralLinkColumns = [
    { id: 'title', label: 'Title'},
    { id: 'url', label: 'URL' },
    { id: 'total_revenue', label: 'Total Revenue'},
    { id: 'referral_history', label: 'Referral History'},
    { id: 'action', label: 'Action'},
  ];

  const getOptionLabel = (option) => option.name || '';
  const getLinkLabel = (link) => link.url || '';

  const onSubmit = async (data) => {
    const newData = {user_id: data.user_id || null, referral_entitlement_id: data.referral_entitlement?.id || null };
    const response =  id ? await updateContracts(newData, id) : await  createContracts(newData)
    if (response?.status === 200 || response?.status === 201) {
      dispatch(getContractsDetail(id));
      enqueueSnackbar(id ? 'Update contract success' : 'Create contract success');
      if (id) {
        navigate(-1);
      } else {
        navigate(-2);
      }
      reset();
    }
    else {
      enqueueSnackbar(response?.error?.detail ||  'Internal server error', { variant: 'error' });
    }
  };

  const handleCloseCreateReferral = () => {
    setSelectedCreateReferral('');
    setLink({})
  };
  const handleCloseRemoveLink = () => {
    setSelectedRemoveLink(null);
  };

  const handleConfirmAddLink = async () => {
    const data = {
      referral_contract_id: contractDetail.id
    };

    const response = await updateLink(data, link.id);
    if (response.status === 200) {
      enqueueSnackbar('Add link success');
      dispatch(getContractsDetail(id))
      setDataChanged(true)
      handleCloseCreateReferral();
    } else {
      enqueueSnackbar(response?.error?.detail ||  'Internal server error', { variant: 'error' });
    }
  };

  const handleConfirmRemoveLink = async () => {
    console.log(selectedRemoveLink)
    const data = {
      remove_contract: true
    };
    const response = await updateLink(data, selectedRemoveLink.id);
    if (response.status === 200) {
      enqueueSnackbar('Remove link success');
      dispatch(getContractsDetail(id))
      setDataChanged(true)
      handleCloseRemoveLink();
    } else {
      enqueueSnackbar(response?.error?.detail ||  'Internal server error', { variant: 'error' });
    }
  };


  return (
      <Page title="Contract Detail">
        {isLoading && <Loading />}
        <Container>
          <HeaderBreadcrumbs
              heading={contractDetail?.phone_number || ''}
              links={[
                { name: 'Affiliate', href: PATH_DASHBOARD.referral.contracts },
                { name:  contractDetail?.phone_number || ''},
              ]}
              action={
                <Stack spacing={2} mt={4} width="100%" direction="row" justifyContent="flex-end">
                  <Button color="inherit" variant="outlined" size="large" onClick={ id ? () => navigate(-1) : () => navigate(-2) }>
                    Back
                  </Button>
                  <LoadingButton variant="contained" loading={isSubmitting} onClick={() => onSubmit(getValues())}>
                    {id ? 'Update' : 'Create'}
                  </LoadingButton>
                </Stack>
              }
          />
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Card mt={2} sx={{ p: 2, mb: 2  }}>
                <Stack spacing={1}>
                  <Typography variant="h4">Information</Typography>
                  <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                    <Grid container spacing={2} sx={{ marginTop: 0 }}>
                      <Grid item xs={2}>
                        <DialogContentTextStyle>ID: </DialogContentTextStyle>
                      </Grid>
                      <Grid item xs={10}>
                        <DialogContentText>{contractDetail?.contract_number || ''}</DialogContentText>
                      </Grid>
                    <Grid item xs={2}>
                      <DialogContentTextStyle>User: </DialogContentTextStyle>
                    </Grid>
                    <Grid item xs={10}>
                        <RHFTextField fullWidth name="user_id" label="User" />
                    </Grid>

                    <Grid item xs={2}>
                      <DialogContentTextStyle>Type: </DialogContentTextStyle>
                    </Grid>
                      <Grid item xs={10}>
                        {/* Autocomplete for Referral Type */}
                        <Autocomplete
                            fullWidth
                            options={entitlements}
                            getOptionLabel={getOptionLabel}
                            renderInput={(params) => (
                                <RHFTextField {...params} fullWidth name="referral_entitlement" label="Type" />
                            )}
                            value={getValues('referral_entitlement') || null}
                            onChange={(_, value) => methods.setValue('referral_entitlement', value, { shouldValidate: true })}
                        />
                      </Grid>
                    </Grid>
                  </FormProvider>
                </Stack>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card mt={2} sx={{ p: 2, mb: 2  }}>
                {/* Table for Referral Type Detail */}
                <Typography variant="h5" mt={3}>
                  Referral Type Detail: {getValues('referral_entitlement')?.name || ''}
                </Typography>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {referralEntitlementDetailColumns.map((column) => (
                            <TableCell key={column.id}>{column.label}</TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {getValues('referral_entitlement')?.referral_entitlement_detail.map((detail) => (
                          <TableRow key={detail.id}>
                            <TableCell>{detail.referral_event?.name}</TableCell>
                            <TableCell>{detail.price*1/100}</TableCell>
                            <TableCell>{detail.currency}</TableCell>
                            <TableCell>{detail.limit}</TableCell>
                          </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Card>
            </Grid>

            <Grid item xs={12} md={12}>
              <Card mt={2} sx={{ p: 2, mb: 2  }}>
                <Stack spacing={1}>
                  {/* Table for Referral Link */}
                  {id && (
                      <Grid item container justifyContent="flex-end">
                        <LoadingButton variant="contained" onClick={(e) => {
                          e.stopPropagation();
                          setSelectedCreateReferral('open')
                        }}>
                          Add Link
                        </LoadingButton>
                      </Grid>
                  )}
                    <Typography variant="h5" mt={2}>
                      Current Referral Link
                    </Typography>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          {referralLinkColumns.map((column) => (
                              <TableCell key={column.id}>{column.label}</TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {contractDetail?.referral_link?.map((link) => (
                            <TableRow style={{ verticalAlign: 'top' }} hover key={link.id} onClick={() => navigate(`/referral/${link.id}`)} >
                              <TableCell>{link?.json_value?.data?.$marketing_title}</TableCell>
                              <TableCell>{link?.url}</TableCell>
                              <TableCell align="left" style={{ verticalAlign: 'top' }}>
                                {link?.referral_history?.length > 0 ? (
                                    <div>
                                      ${link?.referral_history.reduce((sum, historyItem) => sum + Number(historyItem.total_revenue), 0).toFixed(2) / 100}
                                    </div>
                                ) : (
                                    <div>$0.00</div>
                                )}
                              </TableCell>
                              <TableCell align="left" style={{ verticalAlign: 'top' }}>
                                {link?.referral_history?.length > 0 ? (
                                    <div style={{ display: 'grid'}}>
                                      {link?.referral_history?.map((historyItem, index) => (
                                          <Card
                                              key={historyItem.event}
                                              variant="outlined"
                                              sx={{
                                                pl: 1,
                                                color:
                                                    (historyItem.event === 'OPEN' && 'error') ||
                                                    (historyItem.event === 'CLICK' && theme.palette.error.dark) ||
                                                    (historyItem.event === 'INSTALL' && theme.palette.success.dark) ||
                                                    (historyItem.event === 'VERIFIED' && theme.palette.warning.dark) ||
                                                    (historyItem.event === 'REGISTER' && theme.palette.info.dark) || 'success'
                                                ,
                                                border: '1px solid #ccc',
                                                borderRadius: '4px',
                                                mb: 1,
                                              }}
                                          >
                                            <strong >{historyItem.event}</strong>: {historyItem.count}
                                          </Card>
                                      ))}
                                    </div>
                                ) : (
                                    <div>No referral history</div>
                                )}
                              </TableCell>
                              <TableCell align="left" style={{ verticalAlign: 'top' }}>
                                <Tooltip title="Unban user">
                                  <IconButton
                                    size="medium"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setSelectedRemoveLink(link);
                                    }}
                                  >
                                    <Iconify icon={'material-symbols:delete-outline'} />
                                  </IconButton>
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Stack>
              </Card>
            </Grid>
            <Grid item xs={12} md={12}>
              {id ? <Revenues tableHead={TABLE_HEAD} dataChanged={dataChanged} setDataChanged={setDataChanged} defaultContract={contractDetail} /> : <></>}
            </Grid>

            </Grid>
        </Container>
        {selectedCreateReferral === 'open' && (
            <Dialog open={selectedCreateReferral === 'open'} fullWidth maxWidth="xs" onClose={handleCloseCreateReferral}>
              <DialogTitle >Add new referral link</DialogTitle>
              <DialogContent sx={{ mt: 3 }}>
                <Autocomplete
                    fullWidth
                    options={links}
                    getOptionLabel={getLinkLabel}
                    renderInput={(params) => <TextField {...params} label="Link" />}
                    value={link}
                    onChange={(_, value) => {setLink(value)}}
                />
              <DialogContentText sx={{ mt: 2, color: 'warning.main' }}>
                  Please note, once a link is associated with a affiliate, the revenue calculation process will commence and the link cannot be disassociated.
              </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button variant="contained" color="primary" onClick={handleCloseCreateReferral}>
                  Cancel
                </Button>
                <Button variant="contained" color="warning" onClick={handleConfirmAddLink}>
                  Confirm
                </Button>
              </DialogActions>
            </Dialog>
        )}
        {selectedRemoveLink && (
            <Dialog open={Boolean(selectedRemoveLink)} fullWidth maxWidth="xs" onClose={handleCloseRemoveLink}>
              <DialogTitle>Are you sure? </DialogTitle>
              <DialogContent sx={{ mt: 3 }}>
                <DialogContentText sx={{ mt: 2, color: 'warning.main' }}>
                  Please note, once a link is disassociated with a affiliate, the revenue of the affiliate will be recalculated.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button variant="contained" onClick={handleCloseRemoveLink}>
                  Cancel
                </Button>
                <Button variant="contained" color="warning" onClick={handleConfirmRemoveLink}>
                  Confirm
                </Button>
              </DialogActions>
            </Dialog>
        )}
      </Page>
  );
}
