import {createSlice} from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import {dispatch} from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  events: [],
  dates: [],
  data: [],
  total: 0,
  eventDetail: null,
  entitlements: [],
};

const slice = createSlice({
  name: 'event',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET USERS
    getEventsSuccess(state, action) {
      state.isLoading = false;
      state.events = action.payload.events;
      state.dates = action.payload.dates;
    },

    getEventHistorySuccess(state, action) {
      state.isLoading = false;
      state.total = action.payload.total;
      state.data = action.payload.data;
    },
  },
});

// Reducer
export default slice.reducer;
// ----------------------------------------------------------------------

export function getEventHistoryChartData({ ...params }) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/v1/admin/referral/history/get-chart-data', { params });
      dispatch(
        slice.actions.getEventsSuccess({
          events: response.data?.data?.data || [],
          dates: response.data?.data?.labels || [],
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getEventHistory({ ...params }) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/v1/admin/referral/history/get-list', { params });
      dispatch(
        slice.actions.getEventHistorySuccess({
          total: response.data?.meta?.total || 0,
          data: response.data?.data || [],
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

