import React from 'react';
import {capitalCase} from "change-case";
import {useNavigate} from "react-router-dom";
import {
    Box,
    Container, Tab, Tabs,
} from '@mui/material';
import { PATH_DASHBOARD } from '../../../routes/paths';
import Page from '../../../components/Page';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';

import Events from '../../../sections/@dashboard/link/management/Events';

const TABLE_HEAD = [
    { id: 'referral_link_id', label: 'Referral Link', alignRight: false },
    { id: 'event', label: 'Event', alignRight: false },
    { id: 'user_id', label: 'User', alignRight: false },
    { id: 'created_at', label: 'Created At', alignRight: false },
];

export default function LinkEvent() {
    const navigate = useNavigate();
    const PROFILE_TABS = [
        {
            value: 'Events',
            component: <Events tableHead={TABLE_HEAD} />,
        },
    ];

  return (
      <Page title="Link: Tracking">
        <Container>
          <HeaderBreadcrumbs
              heading="Event"
              links={[{ name: 'Event', href: PATH_DASHBOARD.referral.event }, { name: 'Events' }]}
          />
            <Tabs
                value={'Events'}
                scrollButtons="auto"
                variant="scrollable"
                allowScrollButtonsMobile
                sx={{ marginLeft: '24px' }}
            >
                {PROFILE_TABS.map((tab) => (
                    <Tab disableRipple key={tab.value} value={tab.value} icon={tab.icon} label={capitalCase(tab.value)} />
                ))}
            </Tabs>
            {PROFILE_TABS.map((tab) => {
                return <Box key={tab.value}>{tab.component}</Box>;
            })}
        </Container>
      </Page>
  );
}
